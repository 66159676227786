import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBorderIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { ButtonBase, IconButton } from '@mui/material';
import { Screen, ProductsList, Blog } from '../components';
import {__, getImageUrl, getText} from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchBlog, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/public.css';
import 'swiper/css';
import 'swiper/css/scrollbar';

const SliderPrevButton = () => {
    const swiper = useSwiper();

    return (
        <ArrowIcon onClick={() => swiper.slidePrev()} strokeWidth={1} />
    );
}

const SliderNextButton = () => {
    const swiper = useSwiper();

    return (
        <ArrowIcon onClick={() => swiper.slideNext()} strokeWidth={1} />
    );
}

/**
 * Public.
 */
class PublicScreen extends Screen {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        productsType: 'newly',
    };

    title = __('Najlacnejšia online drogéria a kozmetika');

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { fetchBlog } = this.props;

        if (_.isEmpty(this.props.blog)) {
            // Nacitame blog
            await fetchBlog(this);
        }

    }

    /**
     * Event po zmene products type.
     *
     * @param {string} productsType
     */
    onChangeProductsType(productsType) {
        this.setState({ productsType });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, navigate, blog } = this.props;
        const { productsType } = this.state;

        if (_.isEmpty(blog)) {
            return this.renderLoading();
        }

        // Vytiahneme 12 najoblubenejsich produktov
        const popularProducts = _.slice(_.orderBy(_.values(eshop.products), [product => product.ordered], ['desc']), 0, 12);

        // Vytiahneme 12 najnovsich produktov
        const newestProducts = _.slice(_.orderBy(_.values(eshop.products), [product => product.id], ['desc']), 0, 12);

        const products = productsType === 'newly' ? newestProducts : popularProducts;

        return (
            <div className="public">
                <div className="public__header">
                    <div className="public__header__left">
                        <div className="public__header__left__up">
                            <h1 className="public__header__left__up__title">{__('Esodrogéria')}</h1>
                            <h2 className="public__header__left__up__text">{__('Esodrogéria je vaša online drogéria a kozmetika s viac ako 9 000 produktami neznámejších značiek ako Nivea, Persil, Ariel, Dove, Rexona, Ziaja a Eveline za najlepšie ceny na slovenskom webe.')}</h2>
                            <ButtonBase
                                onClick={() => this.redirect('/obchod')}
                            >{__('Do e–shopu')}</ButtonBase>
                        </div>
                        <div className="public__header__left__down">
                            <h3 className="public__header__left__down__title">{__('Naši partneri')}</h3>
                            <div className="public__header__left__down__partners">
                                <IconButton onClick={() => {}}>
                                    <ArrowIcon strokeWidth={1} />
                                </IconButton>
                                <div className="public__header__left__down__partners__content">
                                    <img src={IMAGES['partner-1.png']} alt="partner" />
                                    <img src={IMAGES['partner-2.png']} alt="partner" />
                                    <img src={IMAGES['partner-3.png']} alt="partner" />
                                    <img src={IMAGES['partner-4.png']} alt="partner" />
                                </div>
                                <IconButton onClick={() => {}}>
                                    <ArrowIcon strokeWidth={1} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="public__header__photo">
                        <img src={IMAGES['public-1.png']} alt="public" />
                    </div>
                </div>
                <div className="public__stats">
                    <div className="public__stats__panel">
                        <h2 className="public__stats__panel__text">{__('Lacná kozmetika online')}</h2>
                        <h3 className="public__stats__panel__subtext">{__('V e-shope ponúkame široký sortiment výrobkov pleťovej kozmetiky a dekoratívnej kozmetiky, bio kozmetiky a parfumov a deodorantov. Vyberte si kvalitné farby na vlasy, pleťové krémy a masky, šampóny a sprchové gély, dámsku a pánsku kozmetiku alebo deodoranty svetových značiek za skvelé ceny. U nás môžete nakúpiť kozmetiku lacno.')}</h3>
                    </div>
                    <div className="public__stats__panel">
                        <div className="public__stats__panel__item">
                            <h3 className="public__stats__panel__item__title">{__('Najnižšie ceny drogérie a kozmetiky')}</h3>
                            <div className="public__stats__panel__item__text">{__('Denne sledujeme ceny na internete, aby sme vám priniesli drogériu a kozmetiku za najlepšiu cenu.')}</div>
                        </div>
                        <div className="public__stats__panel__item">
                            <h3 className="public__stats__panel__item__title">{__('100 % spokojnosť s nákupom')}</h3>
                            <div className="public__stats__panel__item__text">{__('Máme takmer 100%-tnú spokojnosť s rýchlosťou a kvalitou objednávok. Stovky spokojných zákazníkov nás chvália na heureka.sk')}</div>
                        </div>
                        <div className="public__stats__panel__item">
                            <h3 className="public__stats__panel__item__title">{__('Tisícky položiek skladom')}</h3>
                            <div className="public__stats__panel__item__text">{__('Máme vyše 9 000 tovarov skladom a sme online drogéria s jednou z najširších ponúk kozmetiky Ziaja a Eveline na Slovensku.')}</div>
                        </div>
                        <div className="public__stats__panel__item">
                            <h3 className="public__stats__panel__item__title">{__('Doprava zadarmo')}</h3>
                            <div className="public__stats__panel__item__text">{__('Stačí, ak nakúpite tovar nad 45 € a za dopravu neplatíte. Doprava zadarmo platí pre celé Slovensko.')}</div>
                        </div>
                    </div>
                </div>
                <div className="public__categories">
                    {_.map(eshop.categories, ({ id, parent_id, name, url, image }) => {
                        if (parent_id > 0) {
                            return null;
                        }

                        return (
                            <ButtonBase
                                onClick={() => this.redirect(getText(url))}
                                className="public__categories__category"
                                key={id}
                            >
                                <h3 className="public__categories__category__name">{getText(name)}</h3>
                                <div className="public__categories__category__link">
                                    <ArrowBorderIcon strokeWidth={1} />
                                    <span>{__('Viac')}</span>
                                </div>
                                <img src={getImageUrl(image)} alt={name} />
                            </ButtonBase>
                        );
                    })}
                </div>
                <div className="public__products">
                    <h2 className="public__products__title">{__('Produkty')}</h2>
                    <div className="public__products__buttons">
                        <div className="public__products__buttons__left">
                            <ButtonBase
                                onClick={() => this.onChangeProductsType('newly')}
                                className={`public__products__buttons__left__button ${productsType === 'newly' ? 'active' : ''}`}
                            >
                                {__('Novinky')}
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => this.onChangeProductsType('popular')}
                                className={`public__products__buttons__left__button ${productsType === 'popular' ? 'active' : ''}`}
                            >
                                {__('Najobľúbenejšie')}
                            </ButtonBase>
                        </div>
                        <div className="public__products__buttons__right">
                            <ButtonBase
                                onClick={() => this.redirect('/obchod')}
                                className="public__products__buttons__right__button"
                            >
                                <ArrowBorderIcon strokeWidth={1} />
                                <span>{__('Zobraziť viac')}</span>
                            </ButtonBase>
                        </div>
                    </div>
                    <ProductsList
                        navigate={navigate}
                        eshop={eshop}
                        items={products}
                        page={1}
                        perPage={12}
                        loadText={__('Do eshopu')}
                        loadCallback={() => this.redirect('/obchod')}
                        showLoad
                    />
                </div>
                <div className="public__references">
                    <div className="public__references__header">
                        <h2 className="public__references__header__title">{__('Referencie')}</h2>
                        <ButtonBase
                            onClick={() => {}}
                            className="public__references__header__button"
                        >
                            <ArrowBorderIcon strokeWidth={1} />
                            <span>{__('Všetky referencie')}</span>
                        </ButtonBase>
                    </div>
                    <div className="public__references__content">
                        <Swiper
                            modules={!this.isMobile() ? [Scrollbar] : []}
                            spaceBetween={0}
                            slidesPerView={this.isMobile() ? 1.5/430*window.innerWidth : 3.5}
                            scrollbar={!this.isMobile() ? { draggable: true } : null}
                        >
                            <div className="public__references__content__arrows">
                                <SliderPrevButton />
                                <SliderNextButton />
                            </div>
                            {_.map(_.values(eshop.references), ({ id, name, team, reference }) => {
                                return (
                                    <SwiperSlide key={id}>
                                        <div
                                            className="public__references__content__reference"
                                        >
                                            <div className="public__references__content__reference__up">„</div>
                                            <div className="public__references__content__reference__text">{getText(reference)}</div>
                                            <div className="public__references__content__reference__name">{name}</div>
                                            <div className="public__references__content__reference__team">{team}</div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div className="public__references__logos">
                        <img src={IMAGES['partner-1.png']} alt="partner" />
                        <img src={IMAGES['partner-2.png']} alt="partner" />
                        <img src={IMAGES['partner-3.png']} alt="partner" />
                        <img src={IMAGES['partner-4.png']} alt="partner" />
                    </div>
                    <div className="public__references__logos-text">{__('a mnoho ďaľších')}...</div>
                </div>
                <div className="public__blog">
                    <h2 className="public__blog__title">{__('Magazín')}</h2>
                    <Blog
                        navigate={navigate}
                        eshop={eshop}
                        categories={blog.categories}
                        items={_.toArray(blog.items)}
                        page={1}
                        perPage={4}
                        loadText={__('Do magazínu')}
                        loadCallback={() => this.redirect('/blog')}
                        showLoad
                    />
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings, blog }) => ({ eshop, settings, blog });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchBlog, fetchFavorites, fetchCompares, fetchLists })(PublicScreen));
