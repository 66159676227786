import React, { Component } from 'react';
import _ from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import { Tabs, Tab, ButtonBase } from '@mui/material';

/**
 * TranslateHtml komponenta.
 */
class TranslateHtml extends Component {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        languages: {},
        translation: '',
        translations: {},
        onSave: () => {},
        onChange: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string,
     * }}
     */
    state = {
        tab: 0,
        translation: '',
        translations: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { translation, translations } = this.props;

        // Nasetujeme value do state z props
        this.setState({ translation, translations });
    }

    /**
     * Event po zmene prekladu html.
     *
     * @param {string} value
     */
    onChangeTranslateHtml(value) {
        const { onChange } = this.props;
        let { translation, translations } = this.state;

        translations = { ...translations, [translation]: value };

        onChange(translations);

        this.setState({ translations });
    }

    /**
     * Event po zmene prekladu v html.
     *
     * @param {number} tab
     * @param {string} code
     */
    onChangeTranslateHtmlCode(tab, code) {
        this.setState({ tab, translation: code });
    }

    /**
     * Ulozenie prekladu html.
     */
    saveTranslateHtml() {
        const { onSave } = this.props;
        const { translations } = this.state;

        onSave(translations);
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { languages } = this.props;
        const { tab, translation, translations } = this.state;

        if (translation === '') {
            return null;
        }

        return (
            <div>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    onChange={(event, value) => this.onChangeTranslateHtmlCode(value, _.values(languages)[value].code)}
                    className="translate-html-lightbox__tabs"
                >
                    {_.map(languages, ({ id, name }) => <Tab key={id} id={id} label={name} />)}
                </Tabs>
                <CKEditor
                    editor={Editor}
                    data={translations[translation]}
                    onChange={(event, editor) => this.onChangeTranslateHtml(editor.getData())}
                />
                <div className="translate-html-lightbox__buttons">
                    <ButtonBase
                        onClick={() => this.saveTranslateHtml()}
                        className="translate-html-lightbox__buttons__button"
                    >Uložit</ButtonBase>
                </div>
            </div>
        );
    }
}

export { TranslateHtml };
