import _ from 'lodash';

/**
 * Nacitame preklad.
 *
 * @param {string} word
 * @param {string} type
 *
 * @returns {string}
 */
export const __ = (word, type = '') => {
    word = _.has(global.translations, global.language) && _.has(global.translations[global.language], word) && !_.isEmpty(global.translations[global.language][word])
        ? global.translations[global.language][word]
        : word;

    switch (type) {
        case 'l':
            return word.toLowerCase();

        case 'u':
            return word.toUpperCase();

        default:
            return word;
    }
};
