import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/contact.css';

/**
 * Contact.
 */
class ContactScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Kontakt');

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop } = this.props;

        return (
            <div>
                <Sections />
                <div className="contact">
                    <div className="contact__header">
                        <div className="contact__header__map">
                            <iframe
                                loading="lazy"
                                src="https://maps.google.com/maps?q=Rudina%207%2002331%20Rudina&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                height="300"
                                frameBorder="0"
                                allowFullScreen=""
                                data-rocket-lazyload="fitvidscompatible"
                                className="lazyloaded"
                                data-ll-status="loaded"
                            />
                        </div>
                        <div className="contact__header__content">
                            <div className="contact__header__content__up">
                                <h1 className="contact__header__content__up__title">{__('Kontakt')}</h1>
                                <div className="contact__header__content__up__text"
                                     dangerouslySetInnerHTML={{ __html: __('Navštívte najväčšiu drogériu v Kysuckom Novom Meste a užite si pohodlný nákup na vyše 300 metroch štvorcových. Vyberajte z najširšej ponuky drogérie a kozmetiky známych značiek za skvelé ceny. Určite oceníte bezplatné parkovanie pred Billou a to, že pri vstupe do predajne nemusíte prekonať žiadne schody - ideálne pre kočíky. ;-) Esodrogéria je skvelá pre mamičky - keď nakupujú, deti si môžu hrať na detskom ihrisku pred predajňou.') }}
                                />
                                <div className="contact__header__content__up__phone">
                                    <PhoneIcon />
                                    <span>{eshop.settings.phone}</span>
                                </div>
                                <div className="contact__header__content__up__email">
                                    <EmailIcon />
                                    <span>{eshop.settings.email}</span>
                                </div>
                            </div>
                            <div className="contact__header__content__down">
                                <div className="contact__header__content__down__panel">
                                    <h2 className="contact__header__content__down__panel__title">{__('Predajňa')}</h2>
                                    <div className="contact__header__content__down__panel__text">
                                        <span>ulica ČSA 1305</span>
                                        <span>024 04 Kysucké Nové Mesto</span>
                                    </div>
                                </div>
                                <div className="contact__header__content__down__panel">
                                    <h2 className="contact__header__content__down__panel__title">{__('Fakturačné údaje')}</h2>
                                    <div className="contact__header__content__down__panel__text">
                                        <span>{eshop.settings.company_name}</span>
                                        <span>{eshop.settings.invoice_address}</span>
                                        <span>{eshop.settings.zip} {eshop.settings.city}</span>
                                        <span>{__('IČO')}: {eshop.settings.ico}</span>
                                        <span>{__('DIČ')}: {eshop.settings.dic}</span>
                                        <span className="mini">{eshop.settings.spis_znacka}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact__persons">
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__text">
                                {__('Všetky otázky, pripomienky a pochvaly môžete písať na')}
                                <span> {eshop.settings.email}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ContactScreen));
