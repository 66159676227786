import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/story.css';

/**
 * O nas.
 */
class StoryScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('O nás');

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('O nás'),
                }} />
                <div className="story">
                    <h1 className="story__title">{__('O nás')}</h1>
                    <div className="story__text">
                        <p>{__('Sme maloobchod s drogériou a kozmetikou. V roku 2003 sme otvorili prvý kamenný maloobchod v Kysuckom Novom Meste o rozlohe 24 m2. Časom sa naša činnosť rozširovala a momentálne máme úspešný obchod z rozlohou viac ako 300 m2, v ktorom naši zákazníci vždy nájdu čo potrebujú a vždy za najlepšie ceny. Aj preto sme sa rozhodli rozbehnúť internetový obchod, aby aj Vy Ste mali možnosť nakúpiť kvalitný tovar za rozumnú cenu.')}</p>
                        <p><b>{__('Pokiaľ Ste nenašli v našom e-shope tovar, ktorý potrebujete, kontaktujte nás, Orange, mail, facebook alebo skype.')}</b></p>
                        <p><b>{__('V pracovný dňoch, v čase od 8,00 hod. do 17,00hod. Vám odpovieme na Vaše požiadavky na cenu, požiadavky na tovar - vo veľmi krátkom čase.')}</b></p>
                        <p>{__('Tešíme sa na našu spoluprácu.')}</p>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(StoryScreen));
