/**
 * Vratime url path.
 *
 * @return {string}
 */
export const getUrlPath = uri => {
    if (global.language === 'cz') {
        switch (uri) {
            case '/moj-ucet/zabudnute-heslo':
                return '/muj-ucet/zapomenute-heslo';

            case '/registracia':
                return '/registrace';

            case '/prihlasenie':
                return '/prihlaseni';

            case '/moj-ucet':
                return '/muj-ucet';

            case '/doprava-platba':
                return '/doprava-platba';

            case '/kontakt':
                return '/kontakt';

            case '/predajne':
                return '/prodejny';

            case '/pokladna':
                return '/pokladna';

            case '/obchod':
                return '/obchod';

            default:
                if (uri.indexOf('moj-ucet/zabudnute-heslo') !== -1) {
                    return uri.replace('moj-ucet/zabudnute-heslo', 'muj-ucet/zapomenute-heslo');
                } else if (uri.indexOf('vysledky-vyhladavania') !== -1) {
                    return uri.replace('vysledky-vyhladavania', 'vysledky-vyhledavani');
                } else if (uri.indexOf('dakujeme') !== -1) {
                    return uri.replace('dakujeme', 'dekujeme');
                } else if (uri.indexOf('obchod') !== -1) {
                    return uri.replace('obchod', 'obchod');
                } else if (uri.indexOf('kategoria') !== -1) {
                    return uri.replace('kategoria', 'kategorie');
                }

                return uri;
        }
    }

    return uri;
};
