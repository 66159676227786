import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { Navigate } from './Navigate';
import ProductsScreen from '../screens/ProductsScreen';
import ProductScreen from '../screens/ProductScreen';
import CheckoutScreen from '../screens/CheckoutScreen';
import ThanksScreen from '../screens/ThanksScreen';
import BlogScreen from '../screens/BlogScreen';
import ArticleScreen from '../screens/ArticleScreen';
import ContactScreen from '../screens/ContactScreen';
import DeliveryScreen from '../screens/DeliveryScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import LostPasswordScreen from '../screens/LostPasswordScreen';
import PublicScreen from '../screens/PublicScreen';
import AdminScreen from '../screens/AdminScreen';
import ShopsScreen from '../screens/ShopsScreen';
import AccountScreen from '../screens/AccountScreen';
import ResultsScreen from '../screens/ResultsScreen';
import FullAdminScreen from '../screens/FullAdminScreen';
import StoryScreen from '../screens/StoryScreen';
import BuyScreen from '../screens/BuyScreen';
import '../assets/styles/content.css';

const ArticleComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ArticleScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.url}
    />;
};

const ProductComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ProductScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${params.url}${_.has(params, 'review') ? '1' : ''}`}
    />;
};

const ThanksComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ThanksScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.id}
    />;
};

const ProductsCategoryComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ProductsScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.category}
    />;
};

const LostPasswordComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <LostPasswordScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.token}
    />;
};

const ProductsSubCategoryComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ProductsScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${params.category}${params.subcategory}`}
    />;
};

const AdminComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <AdminScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.type}
    />;
};

const FullAdminComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <FullAdminScreen
        location={location}
        navigate={navigate}
        params={params}
        key={params.type}
    />;
};

const ResultsComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <ResultsScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${params.type}${params.query}`}
    />;
};

/**
 * Content komponenta.
 */
class Content extends Navigate {

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { location, navigate, params } = this.props;

        return (
            <div className="content">
                <Menu location={location} navigate={navigate} params={params} />
                <div className="content__screen">
                    <Routes>
                        <Route path="/vysledky-vyhladavania/:type/:query" element={<ResultsComponent />} />
                        <Route path="/vysledky-vyhledavani/:type/:query" element={<ResultsComponent />} />
                        <Route path="/muj-ucet/zapomenute-heslo/:token" element={<LostPasswordComponent />} />
                        <Route path="/moj-ucet/zabudnute-heslo/:token" element={<LostPasswordComponent />} />
                        <Route path="/muj-ucet/zapomenute-heslo" element={<LostPasswordScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/moj-ucet/zabudnute-heslo" element={<LostPasswordScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/registrace" element={<RegisterScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/registracia" element={<RegisterScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/prihlaseni" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/prihlasenie" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/muj-ucet" element={<AccountScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/moj-ucet" element={<AccountScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/doprava-platba" element={<DeliveryScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/kontakt" element={<ContactScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/prodejny" element={<ContactScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/predajne" element={<ShopsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/blog" element={<BlogScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/dekujeme/:id/:token" element={<ThanksComponent />} />
                        <Route path="/dakujeme/:id/:token" element={<ThanksComponent />} />
                        <Route path="/pokladna" element={<CheckoutScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/obchod/:url/:review" element={<ProductComponent />} />
                        <Route path="/obchod/:url" element={<ProductComponent />} />
                        <Route path="/zlava-dna" element={<ProductsScreen location={location} navigate={navigate} params={params} key="zlava-dna" />} />
                        <Route path="/novinky" element={<ProductsScreen location={location} navigate={navigate} params={params} key="novinky" />} />
                        <Route path="/akcie" element={<ProductsScreen location={location} navigate={navigate} params={params} key="akcie" />} />
                        <Route path="/kategorie/:category/:subcategory" element={<ProductsSubCategoryComponent />} />
                        <Route path="/kategoria/:category/:subcategory" element={<ProductsSubCategoryComponent />} />
                        <Route path="/kategorie/:category" element={<ProductsCategoryComponent />} />
                        <Route path="/kategoria/:category" element={<ProductsCategoryComponent />} />
                        <Route path="/obchod" element={<ProductsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/admin-light/:type" element={<AdminComponent />} />
                        <Route path="/admin-light" element={<AdminScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/admin/:type" element={<FullAdminComponent />} />
                        <Route path="/admin" element={<FullAdminScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/o-nas" element={<StoryScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/ako-nakupovat" element={<BuyScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/:url" element={<ArticleComponent />} />
                        <Route path="/" element={<PublicScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="*" element={<PublicScreen location={location} navigate={navigate} params={params} />} />
                    </Routes>
                </div>
                <Footer location={location} navigate={navigate} params={params} />
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(Content));
