import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Tooltip, IconButton, Badge, ButtonBase, Popover } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MinusIcon from '@mui/icons-material/Remove';
import PlusIcon from '@mui/icons-material/Add';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import AccountIcon from '@mui/icons-material/PersonOutline';
import CartIcon from '@mui/icons-material/ShoppingBasketOutlined';
import CompareIcon from '@mui/icons-material/CompareArrows';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListsIcon from '@mui/icons-material/PlaylistAdd';
import { Navigate, Input } from '../components';
import Cart from './Cart';
import Favorites from './Favorites';
import Lists from './Lists';
import Compares from './Compares';
import { __, formatAmount, formatDate, getImageUrl, getPrice, getText, toNumber } from '../functions';
import { changeSetting } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/menu.css';

/**
 * Menu.
 */
class Menu extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        infoAnchor: null,
        currencyAnchor: null,
        languageAnchor: null,
        search: '',
        showSearch: false,
        showBig: false,
        searchMobile: '',
        mobileCategory: 0,
    };

    /**
     * Search ref.
     *
     * @type {null}
     */
    searchRef = null;

    /**
     * Big ref.
     *
     * @type {null}
     */
    bigRef = null;

    /**
     * Construktor.
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.searchRef = React.createRef();
        this.bigRef = React.createRef();
    }

    /**
     * Zobrazime info.
     *
     * @param {Object} event
     */
    showInfo(event) {
        this.setState({ infoAnchor: event.currentTarget });
    }

    /**
     * Zobrazime menu.
     *
     * @param {Object} event
     */
    showCurrency(event) {
        this.setState({ currencyAnchor: event.currentTarget, languageAnchor: null });
    }

    /**
     * Zobrazime preklad.
     *
     * @param {Object} event
     */
    showLanguage(event) {
        this.setState({ languageAnchor: event.currentTarget, currencyAnchor: null });
    }

    /**
     * Zavrieme info pop.
     */
    closeInfo() {
        this.setState({ infoAnchor: null });
    }

    /**
     * Zavrieme currency pop.
     */
    closeCurrency() {
        this.setState({ currencyAnchor: null });
    }

    /**
     * Zavrieme language pop.
     */
    closeLanguage() {
        this.setState({ languageAnchor: null });
    }

    /**
     * Zobrazime info.
     *
     * @param {string} uri
     */
    clickInfo(uri) {
        // Zavrieme
        this.closeInfo();

        // Presmerujeme
        this.redirect(uri);
    }

    /**
     * Zmena meny.
     *
     * @param {number} id
     * @param {string} name
     * @param {number} rate
     */
    changeCurrency(id, name, rate) {
        const { changeSetting } = this.props;

        // Nastavime globalne
        global.currency = name;
        global.rate = rate;

        changeSetting(this, 'currency', toNumber(id));

        // Zavrime popover
        this.closeCurrency();
    }

    /**
     * Zmena jazyka.
     *
     * @param {number} id
     * @param {string} code
     */
    changeLanguage(id, code) {
        const { changeSetting } = this.props;

        // Nastavime globalne
        global.language = code;

        document.documentElement.setAttribute('lang', code === 'sk' ? 'sk' : 'hu');

        changeSetting(this, 'language', toNumber(id));

        // Zavrime popover
        this.closeLanguage();
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param {Object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings } = this.props;

        if (settings.showCart && !this.isDrawerOpened('cart')) {
            // Mame otvoreny kosik ale nie je otvoreny
            this.showDrawer('cart');
        } else if (!settings.showCart && this.isDrawerOpened('cart')) {
            // Mame zatvoreny kosik ale je otvoreny
            this.closeDrawer('cart');
        }

        if (settings.showFavorites && !this.isDrawerOpened('favorites')) {
            // Mame otvoreny oblubene ale nie je otvoreny
            this.showDrawer('favorites');
        } else if (!settings.showFavorites && this.isDrawerOpened('favorites')) {
            // Mame zatvoreny oblubene ale je otvoreny
            this.closeDrawer('favorites');
        }

        if (settings.showLists && !this.isDrawerOpened('lists')) {
            // Mame otvoreny zoznamy ale nie je otvoreny
            this.showDrawer('lists');
        } else if (!settings.showLists && this.isDrawerOpened('lists')) {
            // Mame zatvoreny zoznamy ale je otvoreny
            this.closeDrawer('lists');
        }

        if (settings.showCompares && !this.isDrawerOpened('compares')) {
            // Mame otvoreny porovnania ale nie je otvoreny
            this.showDrawer('compares');
        } else if (!settings.showCompares && this.isDrawerOpened('compares')) {
            // Mame zatvoreny porovnania ale je otvoreny
            this.closeDrawer('compares');
        }
    }

    /**
     * Zobrazime kosik.
     */
    showCart() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showCart', true);
    }

    /**
     * Schovame kosik.
     */
    closeCart() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showCart', false);
    }

    /**
     * Zobrazime kosik.
     */
    showFavorites() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showFavorites', true);
    }

    /**
     * Schovame kosik.
     */
    closeFavorites() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showFavorites', false);
    }

    /**
     * Zobrazime kosik.
     */
    showLists() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showLists', true);
    }

    /**
     * Schovame kosik.
     */
    closeLists() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showLists', false);
    }

    /**
     * Zobrazime kosik.
     */
    showCompares() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showCompares', true);
    }

    /**
     * Schovame kosik.
     */
    closeCompares() {
        const { changeSetting } = this.props;

        // Zobrazime kosik
        changeSetting(this, 'showCompares', false);
    }

    /**
     * Zobrazime vyhladavanie.
     */
    showSearch() {
        const showSearch = !this.state.showSearch;

        if (showSearch) {
            // Zobrazujeme vyhladavanie
            setTimeout(() => this.searchRef.current.focus(), 100);
        }

        this.setState({ showSearch, search: '' });
    }

    /**
     * Zobrazime big menu.
     */
    showBig() {
        const showBig = !this.state.showBig;

        if (showBig) {
            // Zobrazujeme big menu
            setTimeout(() => this.bigRef.current.focus(), 100);
        }

        this.setState({ showBig });
    }

    /**
     * Presmerovanie mobilne menu.
     *
     * @param {string} path
     */
    redirectMobileMenu(path) {
        // Zavrieme drawer
        this.closeDrawer('mobile-menu');

        // Presmerujeme
        this.redirect(path);
    }

    /**
     * Rendrujeme mobilne menu.
     *
     * @return {JSX.Element}
     */
    renderMobileMenu() {
        const { eshop, settings } = this.props;
        const { searchMobile, mobileCategory } = this.state;

        // Mena
        const currency = _.has(eshop.currencies, settings.currency)
            ? eshop.currencies[settings.currency]
            : this.getDefaultCurrency();

        // Krajina
        const language = _.has(eshop.languages, settings.language)
            ? eshop.languages[settings.language]
            : this.getDefaultLanguage();

        return (
            <div className="mobile-menu">
                <Input
                    left={<SearchIcon strokeWidth={1} />}
                    onChange={searchMobile => this.setState({ searchMobile })}
                    value={searchMobile}
                    placeholder={__('Hľadať')}
                />
                <div className="mobile-menu__categories">
                    {_.map(eshop.categories, ({ id, parent_id, name, url }) => {
                        if (toNumber(parent_id) > 0) {
                            return null;
                        }

                        const active = id === mobileCategory;
                        const subcategories = _.reduce(eshop.categories, (result, subcategory) => {
                            if (toNumber(subcategory.parent_id) === id) {
                                return result + 1;
                            }

                            return result;
                        }, 0);

                        return (
                            <div className="mobile-menu__categories__category">
                                <ButtonBase
                                    onClick={subcategories > 0
                                        ? (active ? () => this.setState({ mobileCategory: 0 }) : () => this.setState({ mobileCategory: id }))
                                        : () => this.redirectMobileMenu(getText(url))}
                                    className="mobile-menu__categories__category__button"
                                    key={id}
                                >
                                    <div className="mobile-menu__categories__category__button__name">{getText(name)}</div>
                                    {subcategories > 0 ? (active ? <MinusIcon /> : <PlusIcon />) : null}
                                </ButtonBase>
                                {active ? <div className="mobile-menu__categories__category__subcategories">
                                    {_.map(eshop.categories, subcategory => {
                                        if (toNumber(subcategory.parent_id) !== id) {
                                            return null;
                                        }

                                        return (
                                            <ButtonBase
                                                onClick={() => this.redirectMobileMenu(getText(subcategory.url))}
                                                className="mobile-menu__categories__category__subcategories__button"
                                                key={subcategory.id}
                                            >{getText(subcategory.name)}</ButtonBase>
                                        );
                                    })}
                                </div> : null}
                            </div>
                        );
                    })}
                </div>
                <ButtonBase
                    onClick={event => this.showLanguage(event)}
                    className="mobile-menu__section"
                >
                    <div className="mobile-menu__section__left">
                        <div className="mobile-menu__section__left__icon">
                            {language.image !== '' ? <img
                                src={getImageUrl(language.image)}
                                alt={getText(language.name)}
                            /> : null}
                        </div>
                        <div className="mobile-menu__section__left__name">{language.name}</div>
                    </div>
                    <ArrowIcon />
                </ButtonBase>
                <ButtonBase
                    onClick={event => this.showCurrency(event)}
                    className="mobile-menu__section"
                >
                    <div className="mobile-menu__section__left">
                        <div className="mobile-menu__section__left__icon">
                            <span>{currency.name}</span>
                        </div>
                        <div className="mobile-menu__section__left__name">{currency.code}</div>
                    </div>
                    <ArrowIcon />
                </ButtonBase>
                {global.token === '' ? <div className="mobile-menu__down">
                    <ButtonBase
                        onClick={() => this.redirectMobileMenu('/moj-ucet')}
                        className="mobile-menu__down__button"
                    >{__('Prihlásiť sa')}</ButtonBase>
                    <ButtonBase
                        onClick={() => this.redirectMobileMenu('/registracia')}
                        className="mobile-menu__down__button"
                    >{__('Vytvoriť účet')}</ButtonBase>
                </div> : null}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop, settings, navigate, cart, favorites, lists, compares } = this.props;
        const { currencyAnchor, languageAnchor, infoAnchor, search, showSearch, showBig } = this.state;

        if (_.isEmpty(eshop)) {
            // Nie su nacitane data
            return null;
        }

        let searchedProducts = [];
        let searchedBlogs = [];

        if (search !== '') {
            // Vytiahneme vyhladavane produkty
            searchedProducts = _.slice(_.reduce(eshop.products, (result, p) => {
                let valid = false;

                if (getText(p.name).toLowerCase().indexOf(search) !== -1) {
                    // Vyhovuje vyhladavaniu
                    valid = true;
                }

                if (!valid) {
                    // Nevyhovuje
                    return result;
                }

                return [ ...result, p ];
            }, []), 0, 4);

            // Vytiahneme vyhladavane clanky
            searchedBlogs = _.slice(_.reduce(eshop.blogs, (result, b) => {
                let valid = false;

                if (getText(b.name).toLowerCase().indexOf(search) !== -1) {
                    // Vyhovuje vyhladavaniu
                    valid = true;
                }

                if (!valid) {
                    // Nevyhovuje
                    return result;
                }

                return [ ...result, b ];
            }, []), 0, 2);
        }

        // Mena
        const currency = _.has(eshop.currencies, settings.currency)
            ? eshop.currencies[settings.currency]
            : this.getDefaultCurrency();

        // Krajina
        const language = _.has(eshop.languages, settings.language)
            ? eshop.languages[settings.language]
            : this.getDefaultLanguage();

        const path = this.getPath();

        let parents = {};

        // Naformatujeme kategorie podla parentov
        const formattedCategories = _.reduce(eshop.categories, (result, category) => {
            let parentId = toNumber(category.parent_id);
            parentId = parentId > 0 ? parentId : 999;

            if (parentId !== 999 && _.has(parents, parentId)) {
                parentId = parents[parentId];
            }

            if (!_.has(result, parentId)) {
                // Neexistuje
                result = { ...result, [parentId]: [] };
            }

            if (parentId !== 999) {
                parents = { ...parents, [toNumber(category.id)]: parentId };
            }

            return { ...result, [parentId]: [ ...result[parentId], category ] };
        }, {});

        return (
            <div className={`menu ${path === '/pokladna' ? 'menu-checkout' : ''}`}>
                <div className="menu__head" onMouseOver={showBig ? () => this.showBig() : () => {}}>
                    <div className="menu__head__content">
                        <div className="menu__head__content__left">
                            <div className="menu__head__content__left__phone">{eshop.settings.phone}</div>
                            <div className="menu__head__content__left__clock">{__('Po - Pi: 7:30 - 16:30')}</div>
                            <div className="menu__head__content__left__shops">
                                <div className="menu__head__content__left__shops__text">{__('Navštívte nás osobne')}</div>
                                <ButtonBase
                                    onClick={() => this.redirect('/predajne')}
                                >
                                    <span>{__('predajňa v Kysuckom Novom Meste')}</span>
                                    <ArrowIcon />
                                </ButtonBase>
                            </div>
                        </div>
                        <div className="menu__head__content__right">
                            <ButtonBase
                                onClick={event => this.showInfo(event)}
                                className={_.includes(['/'], path) ? 'active' : ''}
                                disableRipple
                            >
                                <span>{__('Informácie')}</span>
                                <ArrowIcon />
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => this.redirect('/blog')}
                                className={path.indexOf('/blog') !== -1 ? 'active' : ''}
                                disableRipple
                            >{__('Magazín')}</ButtonBase>
                            <ButtonBase
                                onClick={() => this.redirect('/kontakt')}
                                className={path.indexOf('/kontakt') !== -1 ? 'active' : ''}
                                disableRipple
                            >{__('Kontakt')}</ButtonBase>
                        </div>
                    </div>
                </div>
                <div className="menu__content">
                    <div className="menu__content__left">
                        <IconButton
                            onClick={() => this.showDrawer('mobile-menu')}
                            className="menu__content__left__button"
                        >
                            <div className="menu__content__left__button__content">
                                <MenuIcon />
                                <span>{__('Menu')}</span>
                            </div>
                        </IconButton>
                    </div>
                    <img
                        onClick={() => window.location = '/'}
                        className="menu__content__logo"
                        src={IMAGES['logo.png']}
                        alt="logo"
                    />
                    <div className="menu__content__buttons">
                        <ButtonBase
                            onClick={() => this.showBig()}
                            onMouseOver={!showBig ? () => this.showBig() : () => {}}
                            className={`${path.indexOf('/obchod') !== -1 || path.indexOf('/kategoria') !== -1 ? 'active' : ''} ${showBig ? 'show-big' : ''}`}
                            disableRipple
                        >
                            <span>{__('Všetky produkty')}</span>
                            <ArrowIcon />
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.redirect('/zlava-dna')}
                            className={`${path === '/zlava-dna' ? 'active' : ''}`}
                            disableRipple
                        >{__('Zľava dňa')}</ButtonBase>
                        <ButtonBase
                            onClick={() => this.redirect('/novinky')}
                            className={`${path === '/novinky' ? 'active' : ''}`}
                            disableRipple
                        >{__('Novinky')}</ButtonBase>
                        <ButtonBase
                            onClick={() => this.redirect('/akcie')}
                            className={`${path === '/akcie' ? 'active' : ''}`}
                            disableRipple
                        >{__('Akcie')}</ButtonBase>
                    </div>
                    <div className="menu__content__right">
                        <Tooltip title={__('Mena')}>
                            <IconButton
                                onClick={event => this.showCurrency(event)}
                                className="menu__content__right__button menu__content__right__button-currency"
                            >
                                <span>{currency.name}</span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={getText(language.name)}>
                            <IconButton
                                onClick={event => this.showLanguage(event)}
                                className="menu__content__right__button menu__content__right__button-country"
                            >
                                {language.image !== '' ? <img
                                    src={getImageUrl(language.image)}
                                    className="menu__content__right__button__country"
                                    alt={getText(language.name)}
                                /> : null}
                            </IconButton>
                        </Tooltip>
                        {_.keys(favorites.items).length > 0 ? <Tooltip title={__('Obľúbené')}><IconButton
                            onClick={() => this.showFavorites()}
                            className="menu__content__right__button menu__content__right__button-favorites"
                        >
                            <Badge badgeContent={_.keys(favorites.items).length} color="primary">
                                <div className="menu__content__right__button__content">
                                    <FavoriteIcon />
                                </div>
                            </Badge>
                        </IconButton></Tooltip> : null}
                        {_.keys(lists.items).length > 0 ? <Tooltip title={__('Moje zoznamy')}><IconButton
                            onClick={() => this.showLists()}
                            className="menu__content__right__button menu__content__right__button-lists"
                        >
                            <Badge badgeContent={_.keys(lists.items).length} color="primary">
                                <div className="menu__content__right__button__content">
                                    <ListsIcon />
                                </div>
                            </Badge>
                        </IconButton></Tooltip> : null}
                        {_.keys(compares.items).length > 0 ? <Tooltip title={__('Moje porovnania')}><IconButton
                            onClick={() => this.showCompares()}
                            className="menu__content__right__button menu__content__right__button-compares"
                        >
                            <Badge badgeContent={_.keys(compares.items).length} color="primary">
                                <div className="menu__content__right__button__content">
                                    <CompareIcon />
                                </div>
                            </Badge>
                        </IconButton></Tooltip> : null}
                        <IconButton
                            onClick={() => this.showSearch()}
                            className="menu__content__right__button menu__content__right__button-search"
                        >
                            <div className="menu__content__right__button__content">
                                <SearchIcon />
                                <span>{__('Hľadať')}</span>
                            </div>
                        </IconButton>
                        <IconButton
                            onClick={() => this.redirect(global.token !== '' ? '/moj-ucet' : '/prihlasenie')}
                            className="menu__content__right__button menu__content__right__button-account"
                        >
                            <div className="menu__content__right__button__content">
                                <AccountIcon />
                                <span>{__('Účet')}</span>
                            </div>
                        </IconButton>
                        <IconButton
                            onClick={() => this.showCart()}
                            className="menu__content__right__button menu__content__right__button-cart"
                        >
                            <Badge badgeContent={_.keys(cart.items).length} color="primary">
                                <div className="menu__content__right__button__content">
                                    <CartIcon />
                                    <span>{__('Košík')}</span>
                                </div>
                            </Badge>
                        </IconButton>
                    </div>
                    <Popover
                        className="pop pop-info"
                        open={infoAnchor !== null}
                        anchorEl={infoAnchor}
                        onClose={() => this.closeInfo()}
                        anchorOrigin={{
                            vertical: 50,
                            horizontal: -100,
                        }}
                    >
                        <ButtonBase
                            onClick={() => this.clickInfo('/o-nas')}
                            className="pop__item"
                        >
                            <span>{__('O nás')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.clickInfo('/ako-nakupovat')}
                            className="pop__item"
                        >
                            <span>{__('Ako nakupovať')}</span>
                        </ButtonBase>
                    </Popover>
                    <Popover
                        className="pop"
                        open={currencyAnchor !== null}
                        anchorEl={currencyAnchor}
                        onClose={() => this.closeCurrency()}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: -50,
                        }}
                    >
                        {_.map(eshop.currencies, ({ id, name, code, rate }) => <ButtonBase
                            onClick={() => this.changeCurrency(id, name, rate)}
                            className="pop__item"
                            key={id}
                        >{code} ({name})</ButtonBase>)}
                    </Popover>
                    <Popover
                        className="pop"
                        open={languageAnchor !== null}
                        anchorEl={languageAnchor}
                        onClose={() => this.closeLanguage()}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: -50,
                        }}
                    >
                        {_.map(eshop.languages, ({ id, image, name, code }) => <ButtonBase
                            onClick={() => this.changeLanguage(id, code)}
                            className="pop__item"
                            key={id}
                        >
                            {image !== '' ? <img className="pop__item__icon" src={getImageUrl(image)} alt={getText(name)} /> : ''}
                            <span>{getText(name)}</span>
                        </ButtonBase>)}
                    </Popover>
                </div>
                {showSearch ? <div className="menu__search">
                    <div className="menu__search__window">
                        <div className="menu__search__window__content">
                            <div className="menu__search__window__content__input">
                                <Input
                                    label={__('Hľadať')}
                                    value={search}
                                    onChange={search => this.setState({ search })}
                                    onBlur={() => setTimeout(() => this.showSearch(), 100)}
                                    inputRef={this.searchRef}
                                />
                                <SearchIcon strokeWidth={1} />
                                <CloseIcon strokeWidth={1} />
                            </div>
                            {search !== '' ? <div className="menu__search__window__content__panels">
                                <div className="menu__search__window__content__panels__products">
                                    <div className="menu__search__window__content__panels__products__title">
                                        {__('Výsledky vyhľadávania "{WORD}"').replace('{WORD}', search)}
                                    </div>
                                    <div className="menu__search__window__content__panels__products__items">
                                        {_.map(searchedProducts, ({ id, image, url, name, price }) => {
                                            name = getText(name);

                                            return (
                                                <ButtonBase
                                                    onClick={() => this.redirect(`/obchod/${getText(url)}`)}
                                                    className="menu__search__window__content__panels__products__items__product"
                                                    key={id}
                                                >
                                                    <div className="menu__search__window__content__panels__products__items__product__photo">
                                                        {image !== '' ? <img src={getImageUrl(image)} alt={name} /> : null}
                                                    </div>
                                                    <div className="menu__search__window__content__panels__products__items__product__info">
                                                        <div className="menu__search__window__content__panels__products__items__product__info__name">
                                                            {name}
                                                        </div>
                                                        <div className="menu__search__window__content__panels__products__items__product__info__price">
                                                            {formatAmount(getPrice(price))}
                                                        </div>
                                                    </div>
                                                </ButtonBase>
                                            );
                                        })}
                                    </div>
                                    <ButtonBase
                                        onClick={() => this.redirect(`/vysledky-vyhladavania/products/${search}`)}
                                        className="menu__search__window__content__panels__products__button"
                                    >
                                        {__('Všetky výsledky vyhľadávania')}
                                    </ButtonBase>
                                </div>
                                <div className="menu__search__window__content__panels__articles">
                                    <div className="menu__search__window__content__panels__articles__title">
                                        {__('Články')}
                                    </div>
                                    {_.map(searchedBlogs, article => {
                                        const { id, url, name, created } = article;

                                        return (
                                            <ButtonBase
                                                onClick={() => this.redirect(`/${getText(url)}`)}
                                                className="menu__search__window__content__panels__articles__article"
                                                key={id}
                                            >
                                                <div className="menu__search__window__content__panels__articles__article__category">
                                                    {_.map(article.categories, category => {
                                                        if (!_.has(eshop.blog_categories, category)) {
                                                            return '';
                                                        }

                                                        return getText(eshop.blog_categories[category].name);
                                                    }).join(' / ')}
                                                </div>
                                                <div className="menu__search__window__content__panels__articles__article__name">{getText(name)}</div>
                                                <div className="menu__search__window__content__panels__articles__article__date">{formatDate(created)}</div>
                                            </ButtonBase>
                                        );
                                    })}
                                    <ButtonBase
                                        onClick={() => this.redirect(`/vysledky-vyhladavania/articles/${search}`)}
                                        className="menu__search__window__content__panels__articles__button"
                                    >
                                        {__('Viac článkov')}
                                    </ButtonBase>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div> : null}
                {showBig ? <div className="menu__big">
                    <div className="menu__big__background" onMouseOver={() => this.showBig()} />
                    <div className="menu__big__window">
                        <div className="menu__big__window__content">
                            <div className="menu__big__window__content__panel">
                                <ButtonBase
                                    onClick={() => this.redirect('/obchod')}
                                    className="menu__big__window__content__panel__title"
                                >{__('Rovno do e-shopu')}</ButtonBase>
                            </div>
                            {_.map(formattedCategories, (categories, parentId) => {
                                parentId = toNumber(parentId);

                                return (
                                    <div className="menu__big__window__content__panel" key={parentId}>
                                        {parentId !== 999 ? <ButtonBase
                                            onClick={() => this.redirect(getText(eshop.categories[parentId].url))}
                                            className="menu__big__window__content__panel__title"
                                        >{getText(eshop.categories[parentId].name)}</ButtonBase> : null}
                                        <div className="menu__big__window__content__panel__links">
                                            {_.map(categories, ({ id, name, url }) => {
                                                if (_.has(formattedCategories, id)) {
                                                    // Nezobrazujeme
                                                    return null;
                                                }

                                                return (
                                                    <ButtonBase
                                                        onClick={() => this.redirect(getText(url))}
                                                        key={id}
                                                    >{getText(name)}</ButtonBase>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <Input
                            onBlur={() => setTimeout(() => this.showBig(), 100)}
                            inputRef={this.bigRef}
                        />
                    </div>
                    <div className="menu__big__back" />
                </div> : null}
                {this.renderDrawer('cart', {
                    title: __('Košík'),
                    content: props => <Cart navigate={navigate} closeCart={() => this.closeCart()} />,
                    closeCallback: () => this.closeCart(),
                })}
                {this.renderDrawer('favorites', {
                    title: __('Obľúbené'),
                    content: props => <Favorites
                        navigate={navigate}
                        closeFavorites={() => this.closeFavorites()}
                        showCart={() => this.showCart()}
                    />,
                    closeCallback: () => this.closeFavorites(),
                })}
                {this.renderDrawer('lists', {
                    title: __('Moje zoznamy'),
                    content: props => <Lists
                        navigate={navigate}
                        closeLists={() => this.closeLists()}
                        showCart={() => this.showCart()}
                    />,
                    closeCallback: () => this.closeLists(),
                })}
                {this.renderDrawer('compares', {
                    title: __('Moje porovnania'),
                    content: props => <Compares
                        navigate={navigate}
                        closeCompares={() => this.closeCompares()}
                        showCart={() => this.showCart()}
                    />,
                    closeCallback: () => this.closeCompares(),
                })}
                {this.renderDrawer('mobile-menu', {
                    title: '',
                    content: props => this.renderMobileMenu(),
                })}
            </div>
        );
    }
}

const stateToProps = ({ eshop, settings, cart, favorites, lists, compares }) => ({ eshop, settings, cart, favorites, lists, compares });

export default withCookies(connect(stateToProps, { changeSetting })(Menu));
