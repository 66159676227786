import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import StoreIcon from '@mui/icons-material/Store';
import EshopIcon from '@mui/icons-material/Tv';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TranslateIcon from '@mui/icons-material/Translate';
import UploadIcon from '@mui/icons-material/FileUpload';
import { ButtonBase, IconButton, TableFooter, TablePagination, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Screen, Input, Select, Checkbox, TranslateHtml} from '../components';
import {formatAmount, formatDate, getText, getUrl, isEmptyString, request, toNumber} from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/full-admin.css';

/**
 * Full Admin.
 */
class FullAdminScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = 'Admin';

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        id: null,
        item: {},
        type: 'orders',
        data: {},
        loadingAdd: false,
        errors: {},
        lightbox: {
            translate: false,
            company: false,
            address: false,
        },
        loadingImg: false,
    };

    /**
     * Sekcie.
     *
     * @type {Object}
     */
    sections = {
        'orders': 'Objednávky',
        'order-states': 'Objednávky - stavy',
        'deliveries': 'Objednávky - dodania',
        'payments': 'Objednávky - platby',
        'products': 'Produkty',
        'product-categories': 'Produkty - kategórie',
        'product-variables': 'Produkty - vlastnosti',
        'product-marks': 'Produkty - značky',
        'product-discounts': 'Produkty - zľavy',
        'storages': 'Sklad',
        'storage-clients': 'Sklad - dodávatelia',
        'users': 'Zákaznici / užívatelia',
        'blogs': 'Blog',
        'blog-categories': 'Blog - kategórie',
        'testimonials': 'Referencie',
        'coupons': 'Kupóny',
        'languages': 'Lokalizácie',
        'translations': 'Preklady',
        'countries': 'Krajiny',
        'currencies': 'Meny',
        'blacklists': 'Blacklist',
        'newsletters': 'Newsletter',
        'settings': 'Nastavenia',
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        this.scrollToTop();

        // Nasetujeme title
        this.setTitle(this.title);

        // Nastavime token
        this.setToken();

        if (global.token === '') {
            window.location = '/prihlasenie?admin=1';
            return;
        }

        const { params } = this.props;

        global.currency = '€';

        await this.changeContent(_.has(params, 'type') ? params.type : 'orders');
    }

    /**
     * Zmenime obsah.
     *
     * @param {string} type
     * @param {number} page
     */
    async changeContent(type, page = 1) {
        this.setState({ data: {}, id: null });

        await request(`/${type}/admin/${page}`).then(response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                window.location = '/';
                return;
            }

            // Nahradime fields v polozkach default itemom
            let items = _.map(data.items, (item) => ({ ...item, data: { ...item.data, fields: data.empty.fields } }));

            this.setState({ type, data: { ...data, items } });
        }).catch(error => window.location = '/');
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        const { type } = this.state;

        this.changeContent(type, page).done();
    }

    /**
     * Event po zmene item.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeItem(name, value) {
        const { item } = this.state;

        this.setState({ item: { ...item, [name]: value } });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [name]: value } });
    }

    /**
     * Event po zmene udaju na adrese.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeAddressData(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, address: { ...lightbox.address, data: { ...lightbox.address.data, [key]: value } } } });
    }

    /**
     * Zmena prekladu.
     *
     * @param {number} id
     * @param {string} country
     * @param {string} value
     */
    onChangeTranslateText(id, country, value) {
        const { data } = this.state;

        const items = _.map(data.items, item => {
            if (id === item.id) {
                item = { ...item, data: { ...item.data, [country]: value } };
            }

            return item;
        });

        this.setState({ data: { ...data, items } });
    }

    /**
     * Event po zmene udaju na firme.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCompanyData(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, company: { ...lightbox.company, data: { ...lightbox.company.data, [key]: value } } } });
    }

    /**
     * Ulozenie adresy.
     */
    saveAddress() {
        const { lightbox } = this.state;

        this.closeLightbox('address');

        this.onChangeItem(lightbox.address.field, lightbox.address.data);
    }

    /**
     * Ulozenie firmy.
     */
    saveCompany() {
        const { lightbox } = this.state;

        this.closeLightbox('company');

        this.onChangeItem('company', lightbox.company.data);
    }

    /**
     * Validacia.
     *
     * @return {Object}
     */
    validate() {
        const { type, item } = this.state;

        let errors = {};

        switch (type) {
            case 'order-states':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.color)) {
                    errors = { ...errors, color: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'deliveries':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.price)) {
                    errors = { ...errors, price: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'payments':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.price)) {
                    errors = { ...errors, price: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'product-categories':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.url)) {
                    errors = { ...errors, url: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'product-variables':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'product-marks':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.color)) {
                    errors = { ...errors, color: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'product-discounts':
                if (toNumber(item.product_id) === 0) {
                    errors = { ...errors, product_id: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.amount)) {
                    errors = { ...errors, amount: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'storages':
                if (toNumber(item.product_id) === 0) {
                    errors = { ...errors, product_id: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.ean)) {
                    errors = { ...errors, ean: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'storage-clients':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'users':
                if (isEmptyString(item.email)) {
                    errors = { ...errors, email: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.type)) {
                    errors = { ...errors, type: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'blogs':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.url)) {
                    errors = { ...errors, url: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.short_text)) {
                    errors = { ...errors, short_text: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'blog-categories':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'testimonials':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.team)) {
                    errors = { ...errors, team: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.reference)) {
                    errors = { ...errors, reference: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'coupons':
                if (isEmptyString(item.value)) {
                    errors = { ...errors, value: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'languages':
                if (isEmptyString(item.code)) {
                    errors = { ...errors, code: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'countries':
                if (isEmptyString(item.code)) {
                    errors = { ...errors, code: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'currencies':
                if (isEmptyString(item.code)) {
                    errors = { ...errors, code: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.rate)) {
                    errors = { ...errors, rate: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'blacklists':
                if (isEmptyString(item.email)) {
                    errors = { ...errors, email: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'newsletters':
                if (isEmptyString(item.email)) {
                    errors = { ...errors, email: 'Toto pole musí byť vyplnené' };
                }
                break;

            case 'products':
                if (isEmptyString(item.name)) {
                    errors = { ...errors, name: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.url)) {
                    errors = { ...errors, url: 'Toto pole musí byť vyplnené' };
                }

                if (isEmptyString(item.price)) {
                    errors = { ...errors, price: 'Toto pole musí byť vyplnené' };
                }
                break;

            default:
                break;
        }

        return errors;
    }

    /**
     * Upload file.
     *
     * @param {number} id
     * @param {Object} file
     */
    uploadFile(file) {
        const { id, type, item } = this.state;

        this.setState({ loadingImg: 'main' });

        // Ulozime
        request(`/${type}/adminUpload/${id}`, file, 'FILE').then(response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                this.setState({ loadingImg: false });
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            this.setState({ loadingImg: false, item: { ...item, image: data.image } });
        });
    }

    /**
     * Ulozime.
     */
    save() {
        const { type, id, item, data } = this.state;

        // Validujeme
        const errors = this.validate();

        if (!_.isEmpty(errors)) {
            // Mame errory
            this.setState({ errors });
            return;
        }

        this.setState({ loadingAdd: true, errors: {} });

        if (type === 'settings') {
            // Nastavenia
            const settingsData = {
                account: data.account,
                address: data.address,
                bic: data.bic,
                city: data.city,
                company_name: data.company_name,
                country: data.country,
                dic: data.dic,
                email: data.email,
                gmaps_apikey: data.gmaps_apikey,
                iban: data.iban,
                ico: data.ico,
                invoice_address: data.invoice_address,
                packeta_token: data.packeta_token,
                phone: data.phone,
                recaptcha_apikey: data.recaptcha_apikey,
                recaptcha_secret: data.recaptcha_secret,
                spis_znacka: data.spis_znacka,
                state_cancel: data.state_cancel,
                state_completed: data.state_completed,
                state_confirm: data.state_confirm,
                state_finish: data.state_finish,
                state_return: data.state_return,
                state_send: data.state_send,
                tax: data.tax,
                zip: data.zip,
            };

            // Ulozime
            request('/settings/adminSave', settingsData, 'POST').then(response => {
                const { status } = response.data;

                this.setState({ loadingAdd: false });

                if (status !== 'success') {
                    this.showSnackbar('error', 'Nastala chyba');
                    return;
                }

                // Nacitame data znovu
                this.changeContent(type).done();
            });

            return;
        } else if (type === 'translations') {
            // Ulozime
            request(`/${type}/adminSave`, { items: data.items }, 'POST').then(response => {
                const { status } = response.data;

                this.setState({ loadingAdd: false });

                if (status !== 'success') {
                    this.showSnackbar('error', 'Nastala chyba');
                    return;
                }

                // Nacitame data znovu
                this.changeContent(type).done();
            });
            return;
        }

        // Ulozime
        request(`/${type}/adminSave`, { items: [ { id, data: item } ] }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loadingAdd: false });

            if (status !== 'success') {
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            // Nacitame data znovu
            this.changeContent(type).done();
        });
    }

    /**
     * Zmazeme.
     *
     * @param {number} id
     */
    delete(id) {
        const { type } = this.state;

        this.setState({ data: {} });

        // Ulozime
        request(`/${type}/adminSave`, { deleted_ids: [ id ] }, 'POST').then(response => {
            // Nacitame data znovu
            this.changeContent(type).done();
        });
    }

    /**
     * Event po zmene prekladu.
     *
     * @param {string} country
     * @param {string} value
     */
    onChangeTranslate(country, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, translate: {
            ...lightbox.translate,
            translations: { ...lightbox.translate.translations, [lightbox.translate.param]: { ...lightbox.translate.translations[lightbox.translate.param], [country]: value } }
        } } });
    }

    /**
     * Ulozenie prekladov.
     */
    saveTranslate() {
        const { lightbox } = this.state;

        this.onChangeItem('translations', lightbox.translate.translations);

        this.closeLightbox('translate');
    }

    /**
     * Zobrazime preklady.
     *
     * @param {string} param
     * @param {Object} translations
     */
    showTranslate(param, translations) {
        if (!_.has(translations, param)) {
            // Param nema preklad, doplnime default
            translations = this.getDefaultTranslations(translations, param);
        }

        this.showLightbox('translate', { param, translations });
    }

    /**
     * Vratime default preklady.
     *
     * @param {Object} translations
     * @param {string} param
     *
     * @return {Object}
     */
    getDefaultTranslations(translations, param) {
        const { data } = this.state;

        return { ...translations, [param]: _.reduce(data.languages, (result, country) => {
            return { ...result, [country.code]: '' };
        }, {}) };
    }

    /**
     * Rendrujeme sekciu.
     *
     * @return {JSX.Element|null}
     */
    renderSection() {
        const { type, data, loadingAdd, loadingImg } = this.state;

        switch (type) {
            case 'orders':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Číslo</TableCell>
                                    <TableCell>Dátum</TableCell>
                                    <TableCell>Objednávateľ</TableCell>
                                    <TableCell>Cena s DPH</TableCell>
                                    <TableCell>Zaplatené</TableCell>
                                    <TableCell>Dodanie</TableCell>
                                    <TableCell>Platba</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.number}</TableCell>
                                            <TableCell>{formatDate(data.date, 'dd.mm.yyyy')}</TableCell>
                                            <TableCell>{data.address.name}</TableCell>
                                            <TableCell>{`${formatAmount(data.amount, 2, ',', false)} ${this.state.data.Currency[data.currency]}`}</TableCell>
                                            <TableCell>{data.payment_status === 'yes' ? 'Áno' : 'Nie'}</TableCell>
                                            <TableCell>{getText(this.state.data.Delivery[data.delivery_id])}</TableCell>
                                            <TableCell>{getText(this.state.data.Payment[data.payment_id])}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        number: data.number,
                                                        state: data.state,
                                                        delivery_id: data.delivery_id,
                                                        payment_id: data.payment_id,
                                                        payment_status: data.payment_status,
                                                        date: data.date,
                                                        amount: data.amount,
                                                        currency: data.currency,
                                                        tax: data.tax,
                                                        company: data.company,
                                                        address: data.address,
                                                        delivery_address: data.delivery_address,
                                                        products: data.products,
                                                        rate: data.rate,
                                                        tracking_number: data.tracking_number,
                                                        tracking_url: data.tracking_url,
                                                        invoice: data.invoice,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={9}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'order-states':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Farba</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell style={{ color: data.color }}>{data.color}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        color: data.color,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'deliveries':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Krajiny</TableCell>
                                    <TableCell>Cena</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{_.join(data.countries, ', ')}</TableCell>
                                            <TableCell>{formatAmount(data.price)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        setting: data.setting,
                                                        countries: data.countries,
                                                        price: data.price,
                                                        info: data.info,
                                                        delivery_text: data.delivery_text,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={5}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'payments':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Krajiny</TableCell>
                                    <TableCell>Cena</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{_.join(data.countries, ', ')}</TableCell>
                                            <TableCell>{formatAmount(data.price)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        setting: data.setting,
                                                        countries: data.countries,
                                                        price: data.price,
                                                        info: data.info,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={5}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'products':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Cena</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{formatAmount(data.price)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        categories: data.categories,
                                                        variables: data.variables,
                                                        variants: data.variants,
                                                        marks: data.marks,
                                                        extensions: data.extensions,
                                                        name: data.name,
                                                        url: data.url,
                                                        short_text: data.short_text,
                                                        text: data.text,
                                                        price: data.price,
                                                        prices: data.prices,
                                                        chart: data.chart,
                                                        seo_description: data.seo_description,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'product-categories':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nadradená</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{toNumber(data.parent_id) > 0 ? getText(this.state.data.ProductCategory[data.parent_id]) : ''}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        parent_id: data.parent_id,
                                                        name: data.name,
                                                        text: data.text,
                                                        url: data.url,
                                                        seo_description: data.seo_description,
                                                        heureka_category: data.heureka_category,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'product-variables':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nadradená</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{toNumber(data.parent_id) > 0 ? getText(this.state.data.ProductVariable[data.parent_id]) : ''}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        parent_id: data.parent_id,
                                                        name: data.name,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'product-marks':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Farba</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell style={{ color: data.color }}>{data.color}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'product-discounts':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Produkt</TableCell>
                                    <TableCell>Typ</TableCell>
                                    <TableCell>Suma</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(this.state.data.Products[data.product_id])}</TableCell>
                                            <TableCell>{this.state.data.Types[data.type]}</TableCell>
                                            <TableCell>{data.amount}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        product_id: data.product_id,
                                                        type: data.type,
                                                        amount: data.amount,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={5}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'storages':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Produkt</TableCell>
                                    <TableCell>Varianta</TableCell>
                                    <TableCell>EAN</TableCell>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Stav</TableCell>
                                    <TableCell>Rezervované</TableCell>
                                    <TableCell>K dispozícii</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(this.state.data.Product[data.product_id])}</TableCell>
                                            <TableCell>{toNumber(data.product_variant_id) > 0 ? getText(this.state.data.ProductVariant[data.product_variant_id]) : ''}</TableCell>
                                            <TableCell>{data.ean}</TableCell>
                                            <TableCell>{data.sku}</TableCell>
                                            <TableCell>{data.stock}</TableCell>
                                            <TableCell>{data.reserved}</TableCell>
                                            <TableCell>{data.ready}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        product_id: data.product_id,
                                                        product_variant_id: data.product_variant_id,
                                                        ean: data.ean,
                                                        sku: data.sku,
                                                        weight: data.weight,
                                                        height: data.height,
                                                        width: data.width,
                                                        length: data.length,
                                                        storage: data.storage,
                                                        stock: data.stock,
                                                        reserved: data.reserved,
                                                        ready: data.ready,
                                                        minus: data.minus,
                                                        minus_text: data.minus_text,
                                                        limit_min: data.limit_min,
                                                        buy_price: data.buy_price,
                                                        buy_currency: data.buy_currency,
                                                        storage_client: data.storage_client,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={9}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'storage-clients':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={3}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'users':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Typ</TableCell>
                                    <TableCell>Kredit</TableCell>
                                    <TableCell>Platnosť kreditu</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.email}</TableCell>
                                            <TableCell>{data.name}</TableCell>
                                            <TableCell>{getText(this.state.data.UserTypes[data.type])}</TableCell>
                                            <TableCell>{formatAmount(data.credit)}</TableCell>
                                            <TableCell>{formatDate(data.credit_end, 'dd.mm.yyyy')}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        email: data.email,
                                                        name: data.name,
                                                        type: data.type,
                                                        confirmed: data.confirmed,
                                                        credit: data.credit,
                                                        credit_end: data.credit_end,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                    disabled={data.type === 'admin'}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={7}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'blogs':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        categories: data.categories,
                                                        name: data.name,
                                                        url: data.url,
                                                        short_text: data.short_text,
                                                        text: data.text,
                                                        tags: data.tags,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={3}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'blog-categories':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={3}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'testimonials':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Firma</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{getText(data.team)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        name: data.name,
                                                        team: data.team,
                                                        reference: data.reference,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'coupons':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>ID objednávky</TableCell>
                                    <TableCell>Kupón</TableCell>
                                    <TableCell>Typ</TableCell>
                                    <TableCell>Hodnota</TableCell>
                                    <TableCell>Platí do</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.order_id}</TableCell>
                                            <TableCell>{data.code}</TableCell>
                                            <TableCell>{this.state.data.Types[data.type]}</TableCell>
                                            <TableCell>{data.value}</TableCell>
                                            <TableCell>{data.date !== '' ? formatDate(data.date, 'dd.mm.yyyy') : ''}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        order_id: data.order_id,
                                                        code: data.code,
                                                        type: data.type,
                                                        value: data.value,
                                                        discount: data.discount,
                                                        products_in: data.products_in,
                                                        products_out: data.products_out,
                                                        categories_in: data.categories_in,
                                                        categories_out: data.categories_out,
                                                        date: data.date,
                                                        users: data.users,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={7}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'languages':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Kód</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Krajiny</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data, image }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.code}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{_.join(data.countries, ', ')}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        code: data.code,
                                                        name: data.name,
                                                        countries: data.countries,
                                                        translations: data.translations,
                                                        image,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={5}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'countries':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Kód</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.code}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        code: data.code,
                                                        name: data.name,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={4}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'currencies':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Kód</TableCell>
                                    <TableCell>Názov</TableCell>
                                    <TableCell>Kurz</TableCell>
                                    <TableCell>Krajiny</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.code}</TableCell>
                                            <TableCell>{getText(data.name)}</TableCell>
                                            <TableCell>{data.rate}</TableCell>
                                            <TableCell>{_.join(data.countries, ', ')}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        code: data.code,
                                                        name: data.name,
                                                        rate: data.rate,
                                                        countries: data.countries,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={6}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'blacklists':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.email}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        email: data.email,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={3}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'newsletters':
                return (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(data.items, ({ id, data }) => {
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>{id}</TableCell>
                                            <TableCell>{data.email}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => this.setState({ id, item: {
                                                        email: data.email,
                                                        translations: data.translations,
                                                    } })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => this.delete(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[data.per_page]}
                                        count={data.count}
                                        rowsPerPage={data.per_page}
                                        page={data.page - 1}
                                        getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                        labelRowsPerPage={'Počet záznamov na stránku'}
                                        onPageChange={(event, page) => this.onChangePage(page + 1)}
                                        onRowsPerPageChange={event => {}}
                                        colSpan={3}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                );

            case 'settings':
                return (
                    <div>
                        <div className="edit__panels">
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Firma'}
                                    value={data.company_name}
                                    onChange={value => this.onChangeSetting('company_name', value)}
                                />
                                <Input
                                    label={'Adresa'}
                                    value={data.address}
                                    onChange={value => this.onChangeSetting('address', value)}
                                />
                                <Input
                                    label={'Mesto'}
                                    value={data.city}
                                    onChange={value => this.onChangeSetting('city', value)}
                                />
                                <Input
                                    label={'PSČ'}
                                    value={data.zip}
                                    onChange={value => this.onChangeSetting('zip', value)}
                                />
                                <Select
                                    label={'Krajina'}
                                    value={data.country}
                                    options={data.countries}
                                    onChange={value => this.onChangeSetting('country', value)}
                                />
                                <Input
                                    label={'Email'}
                                    value={data.email}
                                    onChange={value => this.onChangeSetting('email', value)}
                                />
                                <Input
                                    label={'Tel. číslo'}
                                    value={data.phone}
                                    onChange={value => this.onChangeSetting('phone', value)}
                                />
                                <Input
                                    label={'IČO'}
                                    value={data.ico}
                                    onChange={value => this.onChangeSetting('ico', value)}
                                />
                                <Input
                                    label={'DIČ'}
                                    value={data.dic}
                                    onChange={value => this.onChangeSetting('dic', value)}
                                />
                                <Input
                                    label={'IBAN'}
                                    value={data.iban}
                                    onChange={value => this.onChangeSetting('iban', value)}
                                />
                                <Input
                                    label={'Číslo účtu'}
                                    value={data.account}
                                    onChange={value => this.onChangeSetting('account', value)}
                                />
                                <Input
                                    label={'BIC'}
                                    value={data.bic}
                                    onChange={value => this.onChangeSetting('bic', value)}
                                />
                            </div>
                            <div className="edit__panels__panel">
                                <Input
                                    label={'DPH'}
                                    value={data.tax}
                                    onChange={value => this.onChangeSetting('tax', value)}
                                />
                                <Select
                                    label={'Stav objednávky - prijatá'}
                                    value={data.state_confirm}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_confirm', value)}
                                />
                                <Select
                                    label={'Stav objednávky - vybavená'}
                                    value={data.state_completed}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_completed', value)}
                                />
                                <Select
                                    label={'Stav objednávky - odoslaná'}
                                    value={data.state_send}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_send', value)}
                                />
                                <Select
                                    label={'Stav objednávky - zrušená'}
                                    value={data.state_cancel}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_cancel', value)}
                                />
                                <Select
                                    label={'Stav objednávky - doručená'}
                                    value={data.state_finish}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_finish', value)}
                                />
                                <Select
                                    label={'Stav objednávky - vrátená'}
                                    value={data.state_return}
                                    options={data.states}
                                    onChange={value => this.onChangeSetting('state_return', value)}
                                />
                                <Input
                                    label={'Packeta API key'}
                                    value={data.packeta_token}
                                    onChange={value => this.onChangeSetting('packeta_token', value)}
                                />
                                <Input
                                    label={'Google maps API key'}
                                    value={data.gmaps_apikey}
                                    onChange={value => this.onChangeSetting('gmaps_apikey', value)}
                                />
                                <Input
                                    label={'Recaptcha API key'}
                                    value={data.recaptcha_apikey}
                                    onChange={value => this.onChangeSetting('recaptcha_apikey', value)}
                                />
                                <Input
                                    label={'Recaptcha API secret'}
                                    value={data.recaptcha_secret}
                                    onChange={value => this.onChangeSetting('recaptcha_secret', value)}
                                />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'translations':
                return (
                    <div className="translations">
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Zdroj</TableCell>
                                        {_.map(data.languages, country => <TableCell key={country.code}>{getText(country.name)}</TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(data.items, ({ id, data }) => {
                                        return (
                                            <TableRow key={id}>
                                                <TableCell>{data.source}</TableCell>
                                                {_.map(this.state.data.languages, country => <TableCell key={country.code}>
                                                    <Input
                                                        label={null}
                                                        value={data[country.code]}
                                                        onChange={value => this.onChangeTranslateText(id, country.code, value)}
                                                    />
                                                </TableCell>)}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[data.per_page]}
                                            count={data.count}
                                            rowsPerPage={data.per_page}
                                            page={data.page - 1}
                                            getItemAriaLabel={type => type === 'next' ? 'Ďalšia stránka' : 'Predošlá stránka'}
                                            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                            labelRowsPerPage={'Počet záznamov na stránku'}
                                            onPageChange={(event, page) => this.onChangePage(page + 1)}
                                            onRowsPerPageChange={event => {}}
                                            colSpan={9}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            default:
                return null;
        }
    }

    /**
     * Render add section.
     *
     * @return {JSX.Element|null}
     */
    renderAddSection() {
        const { type, item, data, loadingAdd, errors, loadingImg } = this.state;

        switch (type) {
            case 'order-states':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Farba *'}
                            value={item.color}
                            onChange={value => this.onChangeItem('color', value)}
                            error={_.has(errors, 'color') ? errors.color : ''}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'deliveries':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Cena *'}
                            value={item.price}
                            onChange={value => this.onChangeItem('price', value)}
                            error={_.has(errors, 'price') ? errors.price : ''}
                        />
                        <Select
                            label={'Zoznam krajín'}
                            value={_.join(item.countries, '===')}
                            onChange={value => this.onChangeItem('countries', _.split(value, '==='))}
                            options={_.reduce(data.Country, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Input
                            label={'Doplňujúce informácie'}
                            value={item.info}
                            onChange={value => this.onChangeItem('info', value)}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('info', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Doba doručenia'}
                            value={item.delivery_text}
                            onChange={value => this.onChangeItem('delivery_text', value)}
                        />
                        <Input
                            label={'Textový príznak pre systém (nemeniť)'}
                            value={item.setting}
                            onChange={value => this.onChangeItem('setting', value)}
                        />
                        <div className="input">
                            <div className="input__label">Ikona</div>
                            <div className="input__upload icon">
                                {loadingImg === 'main'
                                    ? this.renderButtonLoading()
                                    : (_.toString(item.image) !== ''
                                        ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                        : <UploadIcon />)
                                }
                                <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'payments':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Cena *'}
                            value={item.price}
                            onChange={value => this.onChangeItem('price', value)}
                            error={_.has(errors, 'price') ? errors.price : ''}
                        />
                        <Select
                            label={'Zoznam krajín'}
                            value={_.join(item.countries, '===')}
                            onChange={value => this.onChangeItem('countries', _.split(value, '==='))}
                            options={_.reduce(data.Country, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Input
                            label={'Doplňujúce informácie'}
                            value={item.info}
                            onChange={value => this.onChangeItem('info', value)}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('info', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Textový príznak pre systém (nemeniť)'}
                            value={item.setting}
                            onChange={value => this.onChangeItem('setting', value)}
                        />
                        <div className="input">
                            <div className="input__label">Ikona</div>
                            <div className="input__upload icon">
                                {loadingImg === 'main'
                                    ? this.renderButtonLoading()
                                    : (_.toString(item.image) !== ''
                                        ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                        : <UploadIcon />)
                                }
                                <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'product-categories':
                return (
                    <div>
                        <Select
                            label={'Nadradená'}
                            value={item.parent_id}
                            onChange={value => this.onChangeItem('parent_id', value)}
                            options={_.reduce(data.ProductCategory, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Popis'}
                            value={item.text}
                            onChange={value => this.onChangeItem('text', value)}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('text', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Url *'}
                            value={item.url}
                            onChange={value => this.onChangeItem('url', value)}
                            error={_.has(errors, 'url') ? errors.url : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('url', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Heureka kategória'}
                            value={item.heureka_category}
                            onChange={value => this.onChangeItem('heureka_category', value)}
                        />
                        <Input
                            label={'SEO popis'}
                            value={item.seo_description}
                            onChange={value => this.onChangeItem('seo_description', value)}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('seo_description', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <div className="input">
                            <div className="input__label">Ikona</div>
                            <div className="input__upload icon">
                                {loadingImg === 'main'
                                    ? this.renderButtonLoading()
                                    : (_.toString(item.image) !== ''
                                        ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                        : <UploadIcon />)
                                }
                                <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'product-variables':
                return (
                    <div>
                        <Select
                            label={'Nadradená'}
                            value={item.parent_id}
                            onChange={value => this.onChangeItem('parent_id', value)}
                            options={_.reduce(data.ProductVariable, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'product-marks':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Farba *'}
                            value={item.color}
                            onChange={value => this.onChangeItem('color', value)}
                            error={_.has(errors, 'color') ? errors.color : ''}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'product-discounts':
                return (
                    <div>
                        <Select
                            label={'Produkt *'}
                            value={item.product_id}
                            onChange={value => this.onChangeItem('product_id', value)}
                            options={_.reduce(data.Products, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            error={_.has(errors, 'product_id') ? errors.product_id : ''}
                            allowEmpty={false}
                        />
                        <Select
                            label={'Typ *'}
                            value={item.type}
                            onChange={value => this.onChangeItem('type', value)}
                            options={_.reduce(data.Types, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            allowEmpty={false}
                        />
                        <Input
                            label={'Suma *'}
                            value={item.amount}
                            onChange={value => this.onChangeItem('amount', value)}
                            error={_.has(errors, 'amount') ? errors.amount : ''}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'storages':
                return (
                    <div>
                        <Select
                            label={'Produkt *'}
                            value={item.product_id}
                            onChange={value => this.onChangeItem('product_id', value)}
                            options={_.reduce(data.Product, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            error={_.has(errors, 'product_id') ? errors.product_id : ''}
                            allowEmpty={false}
                        />
                        <Select
                            label={'Varianta'}
                            value={item.product_variant_id}
                            onChange={value => this.onChangeItem('product_variant_id', value)}
                            options={_.reduce(data.ProductVariant, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                        />
                        <div className="edit__panels">
                            <div className="edit__panels__panel">
                                <Input
                                    label={'EAN *'}
                                    value={item.ean}
                                    onChange={value => this.onChangeItem('ean', value)}
                                    error={_.has(errors, 'ean') ? errors.ean : ''}
                                />
                                <Input
                                    label={'SKU'}
                                    value={item.sku}
                                    onChange={value => this.onChangeItem('sku', value)}
                                />
                                <Input
                                    label={'Váha'}
                                    value={item.weight}
                                    onChange={value => this.onChangeItem('weight', value)}
                                />
                                <Input
                                    label={'Výška'}
                                    value={item.height}
                                    onChange={value => this.onChangeItem('height', value)}
                                />
                                <Input
                                    label={'Šírka'}
                                    value={item.width}
                                    onChange={value => this.onChangeItem('width', value)}
                                />
                                <Input
                                    label={'Dĺžka'}
                                    value={item.length}
                                    onChange={value => this.onChangeItem('length', value)}
                                />
                                <Input
                                    label={'Nákupná cena'}
                                    value={item.buy_price}
                                    onChange={value => this.onChangeItem('buy_price', value)}
                                />
                                <Select
                                    label={'Dodávateľ'}
                                    value={item.storage_client}
                                    onChange={value => this.onChangeItem('storage_client', value)}
                                    options={_.reduce(data.StorageClient, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                />
                            </div>
                            <div className="edit__panels__panel">
                                <Checkbox
                                    label={'Riadiť sklad'}
                                    value={item.storage}
                                    onChange={value => this.onChangeItem('storage', value)}
                                />
                                {item.storage ? <Input
                                    label={'Stav na sklade'}
                                    value={item.stock}
                                    onChange={value => this.onChangeItem('stock', value)}
                                /> : null}
                                {item.storage ? <Input
                                    label={'Rezervované'}
                                    value={item.reserved}
                                    onChange={value => this.onChangeItem('reserved', value)}
                                /> : null}
                                {item.storage ? <Input
                                    label={'K dispozícii'}
                                    value={item.ready}
                                    onChange={value => this.onChangeItem('ready', value)}
                                /> : null}
                                {item.storage ? <Checkbox
                                    label={'Povoliť do mínusu'}
                                    value={item.minus}
                                    onChange={value => this.onChangeItem('minus', value)}
                                /> : null}
                                {item.storage && item.minus ? <Input
                                    label={'Text do mínusu'}
                                    value={item.minus_text}
                                    onChange={value => this.onChangeItem('minus_text', value)}
                                    right={<div className="input__right">
                                        <Tooltip title="Preklady">
                                            <IconButton
                                                onClick={() => this.showTranslate('minus_text', item.translations)}
                                            >
                                                <TranslateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                /> : null}
                                {item.storage ? <Input
                                    label={'Limit nízkeho skladu'}
                                    value={item.limit_min}
                                    onChange={value => this.onChangeItem('limit_min', value)}
                                /> : null}
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'storage-clients':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'users':
                return (
                    <div>
                        <div className="edit__panels">
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Email *'}
                                    value={item.email}
                                    onChange={value => this.onChangeItem('email', value)}
                                    error={_.has(errors, 'email') ? errors.email : ''}
                                />
                                <Input
                                    label={'Názov *'}
                                    value={item.name}
                                    onChange={value => this.onChangeItem('name', value)}
                                    error={_.has(errors, 'name') ? errors.name : ''}
                                />
                                <Select
                                    label={'Typ *'}
                                    value={item.type}
                                    onChange={value => this.onChangeItem('type', value)}
                                    options={_.reduce(data.UserTypes, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <div className="input">
                                    <div className="input__label">Ikona</div>
                                    <div className="input__upload icon">
                                        {loadingImg === 'main'
                                            ? this.renderButtonLoading()
                                            : (_.toString(item.image) !== ''
                                                ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                                : <UploadIcon />)
                                        }
                                        <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                                    </div>
                                </div>
                            </div>
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Kredit'}
                                    value={item.credit}
                                    onChange={value => this.onChangeItem('credit', value)}
                                />
                                <Input
                                    label={'Platnosť kreditu'}
                                    value={item.credit_end}
                                    onChange={value => this.onChangeItem('credit_end', value)}
                                />
                                <Checkbox
                                    label={'Potvrdený email'}
                                    value={item.confirmed}
                                    onChange={value => this.onChangeItem('confirmed', value)}
                                />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'blogs':
                return (
                    <div className="blogs">
                        <Select
                            label={'Kategórie *'}
                            value={_.join(item.categories, '===')}
                            onChange={value => this.onChangeItem('categories', _.split(value, '==='))}
                            options={_.reduce(data.BlogCategory, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            allowEmpty={false}
                            multiple
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Url *'}
                            value={item.url}
                            onChange={value => this.onChangeItem('url', value)}
                            error={_.has(errors, 'url') ? errors.url : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('url', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Krátky popis *'}
                            value={item.short_text}
                            onChange={value => this.onChangeItem('short_text', value)}
                            error={_.has(errors, 'short_text') ? errors.short_text : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('short_text', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <div className="input__label">Popis</div>
                        <TranslateHtml
                            languages={data.languages}
                            tab={0}
                            translation={'sk'}
                            translations={!_.has(item.translations, 'text') ? this.getDefaultTranslations(item.translations, 'text').text : item.translations.text}
                            onChange={data => this.onChangeItem('translations', ({ ...item.translations, text: data }))}
                        />
                        <Input
                            label={'Tagy (oddelte čiarkou)'}
                            value={item.tags}
                            onChange={value => this.onChangeItem('tags', value)}
                            error={_.has(errors, 'tags') ? errors.tags : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('tags', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <div className="input">
                            <div className="input__label">Hlavný obrázok</div>
                            <div className="input__upload">
                                {loadingImg === 'main'
                                    ? this.renderButtonLoading()
                                    : (_.toString(item.image) !== ''
                                        ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                        : <UploadIcon />)
                                }
                                <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'blog-categories':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'testimonials':
                return (
                    <div>
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                        />
                        <Input
                            label={'Firma *'}
                            value={item.team}
                            onChange={value => this.onChangeItem('team', value)}
                            error={_.has(errors, 'team') ? errors.team : ''}
                        />
                        <Input
                            label={'Text *'}
                            value={item.reference}
                            onChange={value => this.onChangeItem('reference', value)}
                            error={_.has(errors, 'reference') ? errors.reference : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('reference', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'coupons':
                return (
                    <div>
                        <Input
                            label={'ID objednávky'}
                            value={item.order_id}
                            onChange={value => this.onChangeItem('order_id', value)}
                            disabled
                        />
                        <Input
                            label={'Kupón'}
                            value={item.code}
                            onChange={value => this.onChangeItem('code', value)}
                            disabled
                        />
                        <Select
                            label={'Typ *'}
                            value={item.type}
                            onChange={value => this.onChangeItem('type', value)}
                            options={_.reduce(data.Types, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            allowEmpty={false}
                        />
                        <Input
                            label={'Hodnota *'}
                            value={item.value}
                            onChange={value => this.onChangeItem('value', value)}
                        />
                        <Input
                            label={'Platí do'}
                            value={item.date}
                            onChange={value => this.onChangeItem('date', value)}
                        />
                        <Checkbox
                            label={'Platí aj pre zľavnené produkty'}
                            value={item.discount}
                            onChange={value => this.onChangeItem('discount', value)}
                        />
                        <Select
                            label={'Platí pre konkrétne produkty'}
                            value={_.join(item.products_in, '===')}
                            onChange={value => this.onChangeItem('products_in', _.split(value, '==='))}
                            options={_.reduce(data.Product, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Select
                            label={'Neplatí pre konkrétne produkty'}
                            value={_.join(item.products_out, '===')}
                            onChange={value => this.onChangeItem('products_out', _.split(value, '==='))}
                            options={_.reduce(data.Product, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Select
                            label={'Platí pre konkrétne kategórie'}
                            value={_.join(item.categories_in, '===')}
                            onChange={value => this.onChangeItem('categories_in', _.split(value, '==='))}
                            options={_.reduce(data.ProductCategory, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Select
                            label={'Neplatí pre konkrétne kategórie'}
                            value={_.join(item.categories_out, '===')}
                            onChange={value => this.onChangeItem('categories_out', _.split(value, '==='))}
                            options={_.reduce(data.ProductCategory, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <Select
                            label={'Platí pre konkrétnych užívatelov'}
                            value={_.join(item.users, '===')}
                            onChange={value => this.onChangeItem('users', _.split(value, '==='))}
                            options={_.reduce(data.User, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'languages':
                return (
                    <div>
                        <Input
                            label={'Kód *'}
                            value={item.code}
                            onChange={value => this.onChangeItem('code', value)}
                            error={_.has(errors, 'code') ? errors.code : ''}
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Select
                            label={'Zoznam krajín'}
                            value={_.join(item.countries, '===')}
                            onChange={value => this.onChangeItem('countries', _.split(value, '==='))}
                            options={_.reduce(data.Country, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <div className="input">
                            <div className="input__label">Ikona</div>
                            <div className="input__upload icon">
                                {loadingImg === 'main'
                                    ? this.renderButtonLoading()
                                    : (_.toString(item.image) !== ''
                                        ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                        : <UploadIcon />)
                                }
                                <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'countries':
                return (
                    <div>
                        <Input
                            label={'Kód *'}
                            value={item.code}
                            onChange={value => this.onChangeItem('code', value)}
                            error={_.has(errors, 'code') ? errors.code : ''}
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'currencies':
                return (
                    <div>
                        <Input
                            label={'Kód *'}
                            value={item.code}
                            onChange={value => this.onChangeItem('code', value)}
                            error={_.has(errors, 'code') ? errors.code : ''}
                        />
                        <Input
                            label={'Názov *'}
                            value={item.name}
                            onChange={value => this.onChangeItem('name', value)}
                            error={_.has(errors, 'name') ? errors.name : ''}
                            right={<div className="input__right">
                                <Tooltip title="Preklady">
                                    <IconButton
                                        onClick={() => this.showTranslate('name', item.translations)}
                                    >
                                        <TranslateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        />
                        <Input
                            label={'Kurz *'}
                            value={item.rate}
                            onChange={value => this.onChangeItem('rate', value)}
                            error={_.has(errors, 'rate') ? errors.rate : ''}
                        />
                        <Select
                            label={'Zoznam krajín'}
                            value={_.join(item.countries, '===')}
                            onChange={value => this.onChangeItem('countries', _.split(value, '==='))}
                            options={_.reduce(data.Country, (result, item, key) => {
                                return { ...result, [key]: getText(item) };
                            }, {})}
                            multiple
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'blacklists':
                return (
                    <div>
                        <Input
                            label={'Email *'}
                            value={item.email}
                            onChange={value => this.onChangeItem('email', value)}
                            error={_.has(errors, 'email') ? errors.email : ''}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'newsletters':
                return (
                    <div>
                        <Input
                            label={'Email *'}
                            value={item.email}
                            onChange={value => this.onChangeItem('email', value)}
                            error={_.has(errors, 'email') ? errors.email : ''}
                        />
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'products':
                return (
                    <div>
                        <div className="edit__panels">
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Názov *'}
                                    value={item.name}
                                    onChange={value => this.onChangeItem('name', value)}
                                    error={_.has(errors, 'name') ? errors.name : ''}
                                    right={<div className="input__right">
                                        <Tooltip title="Preklady">
                                            <IconButton
                                                onClick={() => this.showTranslate('name', item.translations)}
                                            >
                                                <TranslateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                />
                                <Input
                                    label={'Url *'}
                                    value={item.url}
                                    onChange={value => this.onChangeItem('url', value)}
                                    error={_.has(errors, 'url') ? errors.url : ''}
                                    right={<div className="input__right">
                                        <Tooltip title="Preklady">
                                            <IconButton
                                                onClick={() => this.showTranslate('url', item.translations)}
                                            >
                                                <TranslateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                />
                                <Input
                                    label={'Cena *'}
                                    value={item.price}
                                    onChange={value => this.onChangeItem('price', value)}
                                    error={_.has(errors, 'price') ? errors.price : ''}
                                />
                                <Select
                                    label={'Kategórie *'}
                                    value={_.join(item.categories, '===')}
                                    onChange={value => this.onChangeItem('categories', _.split(value, '==='))}
                                    options={_.reduce(data.ProductCategory, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    multiple
                                />
                                <Select
                                    label={'Vlastnosti'}
                                    value={_.join(item.variables, '===')}
                                    onChange={value => this.onChangeItem('variables', _.split(value, '==='))}
                                    options={_.reduce(data.ProductVariable, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    multiple
                                />
                                <Select
                                    label={'Značky'}
                                    value={_.join(item.marks, '===')}
                                    onChange={value => this.onChangeItem('marks', _.split(value, '==='))}
                                    options={_.reduce(data.ProductMark, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    multiple
                                />
                                <Select
                                    label={'Varianty'}
                                    value={_.join(item.variants, '===')}
                                    onChange={value => this.onChangeItem('variants', _.split(value, '==='))}
                                    options={_.reduce(data.ProductVariant, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    multiple
                                />
                                <div className="input">
                                    <div className="input__label">Hlavný obrázok</div>
                                    <div className="input__upload">
                                        {loadingImg === 'main'
                                            ? this.renderButtonLoading()
                                            : (_.toString(item.image) !== ''
                                                ? <img src={`${getUrl()}${item.image}?v=${Math.random()}`} />
                                                : <UploadIcon />)
                                        }
                                        <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                                    </div>
                                </div>
                            </div>
                            <div className="edit__panels__panel">
                                <div className="input__label">Krátky popis</div>
                                <TranslateHtml
                                    languages={data.languages}
                                    tab={0}
                                    translation={'sk'}
                                    translations={!_.has(item.translations, 'short_text') ? this.getDefaultTranslations(item.translations, 'short_text').short_text : item.translations.short_text}
                                    onChange={data => this.onChangeItem('translations', ({ ...item.translations, short_text: data }))}
                                />
                                <div className="input__label">Popis</div>
                                <TranslateHtml
                                    languages={data.languages}
                                    tab={0}
                                    translation={'sk'}
                                    translations={!_.has(item.translations, 'text') ? this.getDefaultTranslations(item.translations, 'text').text : item.translations.text}
                                    onChange={data => this.onChangeItem('translations', ({ ...item.translations, text: data }))}
                                />
                                <Input
                                    label={'SEO popis'}
                                    value={item.seo_description}
                                    onChange={value => this.onChangeItem('seo_description', value)}
                                    right={<div className="input__right">
                                        <Tooltip title="Preklady">
                                            <IconButton
                                                onClick={() => this.showTranslate('seo_description', item.translations)}
                                            >
                                                <TranslateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>}
                                />
                            </div>
                        </div>
                        <ButtonBase
                            onClick={() => this.save()}
                            className="save"
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                );

            case 'orders':
                return (
                    <div className="orders">
                        <div className="edit__panels">
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Číslo *'}
                                    value={item.number}
                                    onChange={value => this.onChangeItem('number', value)}
                                    error={_.has(errors, 'number') ? errors.number : ''}
                                />
                                <Select
                                    label={'Stav'}
                                    value={item.state}
                                    onChange={value => this.onChangeItem('state', value)}
                                    options={_.reduce(data.OrderState, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <Select
                                    label={'Dodanie'}
                                    value={item.delivery_id}
                                    onChange={value => this.onChangeItem('delivery_id', value)}
                                    options={_.reduce(data.Delivery, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <Select
                                    label={'Platba'}
                                    value={item.payment_id}
                                    onChange={value => this.onChangeItem('payment_id', value)}
                                    options={_.reduce(data.Payment, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <Select
                                    label={'Uhradené'}
                                    value={item.payment_status}
                                    onChange={value => this.onChangeItem('payment_status', value)}
                                    options={_.reduce(data.PaymentStatus, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <Input
                                    label={'Dátum'}
                                    value={item.date}
                                    onChange={value => this.onChangeItem('date', value)}
                                />
                                <Input
                                    label={'Suma'}
                                    value={item.amount}
                                    onChange={value => this.onChangeItem('amount', value)}
                                />
                                <Select
                                    label={'Mena'}
                                    value={item.currency}
                                    onChange={value => this.onChangeItem('currency', value)}
                                    options={_.reduce(data.Currency, (result, item, key) => {
                                        return { ...result, [key]: getText(item) };
                                    }, {})}
                                    allowEmpty={false}
                                />
                                <Input
                                    label={'DPH'}
                                    value={item.tax}
                                    onChange={value => this.onChangeItem('tax', value)}
                                />
                            </div>
                            <div className="edit__panels__panel">
                                <Input
                                    label={'Číslo zásielky'}
                                    value={item.tracking_number}
                                    onChange={value => this.onChangeItem('tracking_number', value)}
                                />
                                <Input
                                    label={'Sledovanie zásielky'}
                                    value={item.tracking_url}
                                    onChange={value => this.onChangeItem('tracking_url', value)}
                                />
                                <Input
                                    label={'Faktúra'}
                                    value={item.invoice}
                                    onChange={value => this.onChangeItem('invoice', value)}
                                />
                                <Input
                                    label={'Kurz'}
                                    value={item.rate}
                                    onChange={value => this.onChangeItem('rate', value)}
                                />
                                <ButtonBase
                                    onClick={() => this.showLightbox('address', {
                                        field: 'address',
                                        data: item.address,
                                        errors: {},
                                    })}
                                >Upraviť fakturačnú adresu</ButtonBase>
                                <ButtonBase
                                    onClick={() => this.showLightbox('address', {
                                        field: 'delivery_address',
                                        data: item.delivery_address,
                                        errors: {},
                                    })}
                                >Upraviť dodaciu adresu</ButtonBase>
                                <ButtonBase
                                    onClick={() => this.showLightbox('company', {
                                        data: item.company,
                                        errors: {},
                                    })}
                                >Upraviť firemné údaje</ButtonBase>
                                <ButtonBase
                                    onClick={() => {}}
                                >Upraviť produkty</ButtonBase>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    }

    /**
     * Render add.
     *
     * @return {JSX.Element}
     */
    renderAdd() {
        const { loadingAdd, id } = this.state;

        return (
            <div className="full-admin__content__screen">
                <div className="full-admin__content__screen__header">{id === 0 ? 'Nový záznam' : 'Úprava záznamu'}</div>
                <div className="full-admin__content__screen__content">
                    <div className="full-admin__content__screen__content__header">
                        <ButtonBase
                            onClick={() => this.setState({ id: null, item: {}, errors: {} })}
                            className="back"
                        >Naspäť</ButtonBase>
                        <ButtonBase
                            onClick={() => this.save()}
                        >{loadingAdd ? this.renderButtonLoading() : 'Uložiť'}</ButtonBase>
                    </div>
                    {this.renderAddSection()}
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, data, type, lightbox } = this.state;

        if (_.isEmpty(data)) {
            // Nemame data
            return this.renderLoading();
        }

        const languages = _.reduce(data.languages, (result, language) => {
            return { ...result, [language.code]: language.name };
        }, {});

        let content = id !== null ? this.renderAdd() : <div className="full-admin__content__screen">
            <div className="full-admin__content__screen__header">{this.sections[type]}</div>
            <div className="full-admin__content__screen__content">
                <div className="full-admin__content__screen__content__header">
                    <div> </div>
                    {type !== 'users' && type !== 'orders' && type !== 'settings' && type !== 'translations' ? <ButtonBase
                        onClick={() => {
                            let item = _.reduce(_.keys(data.empty.fields), (result, field) => {
                                return { ...result, [field]: data.empty[field] };
                            }, {});

                            if (type === 'coupons') {
                                item = { ...item, code: data.codes[0] };
                            }

                            this.setState({ id: 0, item });
                        }}
                    >{'Nový záznam'}</ButtonBase> : null}
                </div>
                {this.renderSection()}
            </div>
        </div>;

        return (
            <div className="full-admin">
                <div className="full-admin__header">
                    <img src={IMAGES['logo.png']} alt="logo" />
                    <div className="full-admin__header__buttons">
                        <IconButton
                            onClick={() => window.location = '/obchod'}
                        >
                            <EshopIcon />
                            <span>Do eshopu</span>
                        </IconButton>
                        <IconButton
                            onClick={() => window.location = '/logout'}
                        >
                            <LogoutIcon />
                            <span>Odhlásenie</span>
                        </IconButton>
                    </div>
                </div>
                <div className="full-admin__content">
                    <div className="full-admin__content__menu">
                        <div className="full-admin__content__menu__header">
                            <StoreIcon />
                            <span>Eshop</span>
                        </div>
                        {_.map(this.sections, (name, key) => {
                            return (
                                <ButtonBase
                                    onClick={() => window.location = `/admin/${key}`}
                                    className={key === type ? 'active' : ''}
                                    key={key}
                                >
                                    <span>{name}</span>
                                    <ArrowIcon />
                                </ButtonBase>
                            );
                        })}
                    </div>
                    {content}
                </div>
                {!_.isEmpty(lightbox.translate) ? this.renderLightbox(
                    'translate',
                    'Preklady',
                    <div className="translate-lightbox">
                        {_.map(lightbox.translate.translations[lightbox.translate.param], (value, country) => {
                            if (!_.has(languages, country)) {
                                return null;
                            }

                            return <Input
                                label={getText(languages[country])}
                                value={value}
                                onChange={value => this.onChangeTranslate(country, value)}
                                key={country}
                            />;
                        })}
                    </div>,
                    'Uložiť',
                    'Zrušit',
                    () => this.saveTranslate()
                ) : null}
                {!_.isEmpty(lightbox.company) ? this.renderLightbox(
                    'company',
                    'Firma',
                    <div className="company-lightbox">
                        <Input
                            label={'IČO'}
                            value={lightbox.company.data.ico}
                            onChange={value => this.onChangeCompanyData('ico', value)}
                            error={_.has(lightbox.company.errors, 'ico') ? lightbox.company.errors.ico : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'DIČ'}
                            value={lightbox.company.data.dic}
                            onChange={value => this.onChangeCompanyData('dic', value)}
                            error={_.has(lightbox.company.errors, 'dic') ? lightbox.company.errors.dic : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'IČ DPH'}
                            value={lightbox.company.data.ic_dph}
                            onChange={value => this.onChangeCompanyData('ic_dph', value)}
                            error={_.has(lightbox.company.errors, 'ic_dph') ? lightbox.company.errors.ic_dph : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'VIES datum'}
                            value={lightbox.company.data.vies_date}
                            onChange={value => this.onChangeCompanyData('vies_date', value)}
                            error={_.has(lightbox.company.errors, 'vies_date') ? lightbox.company.errors.vies_date : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'VIES token'}
                            value={lightbox.company.data.vies_token}
                            onChange={value => this.onChangeCompanyData('vies_token', value)}
                            error={_.has(lightbox.company.errors, 'vies_token') ? lightbox.company.errors.vies_token : ''}
                            variant="outlined"
                        />
                    </div>,
                    'Uložit',
                    'Zrušit',
                    () => this.saveCompany(),
                    false,
                    false
                ) : null}
                {!_.isEmpty(lightbox.address) ? this.renderLightbox(
                    'address',
                    lightbox.address.field === 'address' ? 'Fakturačná adresa' : 'Dodacia adresa',
                    <div className="address-lightbox">
                        <div className="address-lightbox__panels">
                            <div className="address-lightbox__panels__panel">
                                <Input
                                    label={'Spoločnosť'}
                                    value={lightbox.address.data.company_name}
                                    onChange={value => this.onChangeAddressData('company_name', value)}
                                    error={_.has(lightbox.address.errors, 'company_name') ? lightbox.address.errors.company_name : ''}
                                    variant="outlined"
                                />
                                <Input
                                    label={'Meno'}
                                    value={lightbox.address.data.name}
                                    onChange={value => this.onChangeAddressData('name', value)}
                                    error={_.has(lightbox.address.errors, 'name') ? lightbox.address.errors.name : ''}
                                    variant="outlined"
                                />
                                <Input
                                    label={'Telefón'}
                                    value={lightbox.address.data.phone}
                                    onChange={value => this.onChangeAddressData('phone', value)}
                                    error={_.has(lightbox.address.errors, 'phone') ? lightbox.address.errors.phone : ''}
                                    variant="outlined"
                                    required
                                />
                                {_.has(lightbox.address.data, 'email') ? <Input
                                    label={'E-mailová adresa'}
                                    value={lightbox.address.data.email}
                                    onChange={value => this.onChangeAddressData('email', value)}
                                    error={_.has(lightbox.address.errors, 'email') ? lightbox.address.errors.email : ''}
                                    variant="outlined"
                                    required
                                /> : null}
                                {_.has(lightbox.address.data, 'comment') ? <Input
                                    label={'Poznámka k objednávke'}
                                    value={lightbox.address.data.comment}
                                    onChange={value => this.onChangeAddressData('comment', value)}
                                    variant="outlined"
                                    multiline
                                /> : null}
                            </div>
                            <div className="address-lightbox__panels__panel">
                                <Input
                                    label={'Ulica a č.p.'}
                                    value={lightbox.address.data.address}
                                    onChange={value => this.onChangeAddressData('address', value)}
                                    variant="outlined"
                                    required
                                    error={_.has(lightbox.address.errors, 'address') ? lightbox.address.errors.address : ''}
                                />
                                <Input
                                    label={'Mesto'}
                                    value={lightbox.address.data.city}
                                    onChange={value => this.onChangeAddressData('city', value)}
                                    error={_.has(lightbox.address.errors, 'city') ? lightbox.address.errors.city : ''}
                                    variant="outlined"
                                    required
                                />
                                <Input
                                    label={'PSČ'}
                                    value={lightbox.address.data.zip}
                                    onChange={value => this.onChangeAddressData('zip', value)}
                                    error={_.has(lightbox.address.errors, 'zip') ? lightbox.address.errors.zip : ''}
                                    variant="outlined"
                                    required
                                />
                                <Select
                                    label={'Krajina'}
                                    options={data.countries}
                                    value={lightbox.address.data.country}
                                    onChange={value => this.onChangeAddressData('country', value)}
                                    allowEmpty={false}
                                    variant="outlined"
                                    required
                                />
                            </div>
                        </div>
                    </div>,
                    'Uložit',
                    'Zrušit',
                    () => this.saveAddress(),
                    false,
                    false
                ) : null}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ eshop }) => ({ eshop });

export default withCookies(connect(stateToProps)(FullAdminScreen));
