import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase, Chip } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAlt';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Screen, Sections, ProductsList, Filter } from '../components';
import {
    __,
    toNumber,
    getText,
    round,
    getImageUrl,
    formatAmount,
    getPrice,
    getUrlPath,
    plural,
    floor,
    ceil,
} from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/products.css';

/**
 * Produkty.
 */
class ProductsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Eshop');

    /**
     * Default filter.
     *
     * @type {Object}
     */
    state = {
        filter: {},
        categories: {},
        variables: {},
        marks: {},
        products: [],
        subcategories: {},
        subvariables: {},
        variants: {},
        filterClosed: {},
        minPrice: 0,
        maxPrice: 0,
        page: 1,
        perPage: 12,
        sort: 'popular',
        hiddenSections: {},
        popularProductsPage: 1,
        popularProductsPerPage: 4,
        showMoreCategoryText: false,
    };

    /**
     * Url kategoria.
     *
     * @type {string}
     */
    urlCategory = '';

    /**
     * Url subkategoria.
     *
     * @type {string}
     */
    urlSubCategory = '';

    /**
     * Filter.
     *
     * @type {Object|null}
     */
    filter = null;
    category = null;
    subcategory = null;

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { sort } = this.state;
        const { eshop, params } = this.props;

        // Vytiahneme hlavne kategorie
        const categories = _.reduce(
            eshop.categories,
            (result, category) => (toNumber(category.parent_id) === 0 ? { ...result, [category.id]: category } : result),
            {}
        );

        // Vytiahneme hlavne kategorie
        const subcategories = _.reduce(
            eshop.categories,
            (result, category) => (toNumber(category.parent_id) > 0 ? { ...result, [category.id]: category } : result),
            {}
        );

        // Vytiahneme hlavne variables
        let variables = _.reduce(
            eshop.variables,
            (result, variable) => (toNumber(variable.parent_id) === 0 ? { ...result, [variable.id]: { ...variable, sub: [] } } : result),
            {}
        );

        // Doplnime options do variables
        _.each(eshop.variables, variable => {
            if (toNumber(variable.parent_id) > 0) {
                variables = { ...variables, [variable.parent_id]: {
                    ...variables[variable.parent_id],
                    sub: [ ...variables[variable.parent_id].sub, variable.id ]
                } };
            }
        });

        // Aktivna je prva kategoria
        //let category = categories[_.keys(categories)[0]].id;
        let category = 0;
        let subcategory = 0;

        if (_.has(params, 'category')) {
            // Filtrujeme podla kategorie
            const category_url = `kategoria/${params.category}`;

            _.each(categories, ({ id, url }) => {
                if (getText(url) === getUrlPath(category_url)) {
                    category = id;
                }
            });

            if (_.has(params, 'subcategory')) {
                // Filtrujeme aj podla subkategorie
                const subcategory_url = `${category_url}/${params.subcategory}`;

                _.each(eshop.categories, ({ id, url }) => {
                    if (getText(url) === getUrlPath(subcategory_url)) {
                        subcategory = id;
                    }
                });
            }
        }

        let marks = {};

        if (window.location.pathname === '/zlava-dna') {
            marks = { 1: 1 };
        } else if (window.location.pathname === '/novinky') {
            marks = { 2: 2 };
        } else if (window.location.pathname === '/akcie') {
            marks = { 3: 3 };
        }

        this.urlCategory = _.has(categories, category) ? getText(categories[category].name) : '';
        this.urlSubCategory = _.has(subcategories, subcategory) ? getText(subcategories[subcategory].name) : '';

        // Zakladny filter
        const filter = { category, ...this.getDefaultFilter(), subcategory, marks };

        this.setState({ categories, variables, filter, ...this.filterProducts(filter, sort) });

        this.category = _.has(categories, category) ? categories[category] : null;
        this.subcategory = _.has(subcategories, subcategory) ? subcategories[subcategory] : null;

        this.setMeta();
    }

    /**
     * Vratime default filter.
     *
     * @return {Object}
     */
    getDefaultFilter() {
        return { subcategory: 0, variables: {}, variants: {}, marks: {}, storage: false, price: [0, 100] };
    }

    /**
     * Resetujem filter.
     */
    resetFilter() {
        let { filter, sort } = this.state;

        filter = { ...filter, ...this.getDefaultFilter() };

        this.setState({ page: 1, filter, ...this.filterProducts(filter, sort) });
    }

    /**
     * Event po zmene kategorie.
     *
     * @param {number} id
     */
    onChangeCategory(id) {
        // Scrollneme navrh
        this.scrollToTop();

        const { categories, sort } = this.state;

        const filter = { category: id, ...this.getDefaultFilter() };

        this.setState({ page: 1, filter, ...this.filterProducts(filter, sort), showMoreCategoryText: false });

        this.filter = filter;
        this.category = _.has(categories, id) ? categories[id] : null;
        this.subcategory = null;
        this.setMeta();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {string} param
     * @param {number|string|Object} value
     */
    onChangeFilter(param, value) {
        let { filter, sort, subcategories } = this.state;

        if (_.isObject(value) && !_.isArray(value)) {
            if (!_.isEmpty(value)) {
                const key = _.keys(value)[0];
                const v = _.values(value)[0];

                if (_.has(filter[param], key) && filter[param][key] === v) {
                    // Je zadane a klikame znova, musime zrusit
                    _.unset(filter[param], key);
                } else {
                    filter = { ...filter, [param]: { ...filter[param], ...value } };
                }
            } else {
                // Resetujeme cele
                filter = { ...filter, [param]: {} };
            }
        } else {
            // Ak je hodnota inak tak nasetujeme, inak resetujeme
            value = filter[param] !== value ? value : 0;

            filter = { ...filter, [param]: value };
        }

        if (param === 'subcategory') {
            this.filter = filter;
            this.category = null;
            this.subcategory = _.has(subcategories, value) ? subcategories[value] : null;
            this.setMeta();
        }

        this.setState({ page: 1, filter, ...this.filterProducts(filter, sort) });
    }

    /**
     * Vratime zoznam produktov.
     *
     * @param {Object} filter
     * @param {string} sort
     *
     * @return {Object}
     */
    filterProducts(filter, sort) {
        const { eshop } = this.props;

        // Vytiahneme produkty ktore vyhovuju hlavnej kategorii
        const categoryProducts = toNumber(filter.category) === 0 ? _.values(eshop.products) : _.reduce(
            eshop.products,
            (result, product) => (_.includes(product.categories, toNumber(filter.category))
                ? [ ...result, product ]
                : result),
            []
        );

        let subcategories = {};
        let subvariables = {};
        let allvariants = {};
        let allmarks = {};
        let minPrice = 0;
        let maxPrice = 0;

        // Prejdeme zoznam produktov pod hlavnou kategoriou a nastavime data
        _.each(categoryProducts, ({ categories, variables, variants, marks, price }) => {
            // Pridame subkategorie
            _.each(categories, category => {
                if (!_.has(subcategories, category)) {
                    subcategories = { ...subcategories, [category]: {
                        name: getText(eshop.categories[category].name),
                        url: getText(eshop.categories[category].url),
                        image: eshop.categories[category].image,
                        count: 0
                    } };
                }

                subcategories[category].count++;
            });

            // Pridame subvariable
            _.each(variables, variable => {
                if (!_.has(subvariables, variable)) {
                    subvariables = { ...subvariables, [variable]: 0 };
                }

                subvariables[variable]++;
            });

            // Pridame varianty
            _.each(variants, ({ id }) => {
                if (id === 0) {
                    // Bez varianty
                    return;
                }

                if (!_.has(allvariants, id)) {
                    allvariants = { ...allvariants, [id]: 0 };
                }

                allvariants[id]++;
            });

            // Pridame znacky
            _.each(marks, mark => {
                if (!_.has(allmarks, mark)) {
                    allmarks = { ...allmarks, [mark]: 0 };
                }

                allmarks[mark]++;
            });

            minPrice = price < minPrice || minPrice === 0 ? floor(price, 0) : minPrice;
            maxPrice = price > maxPrice || maxPrice === 0 ? ceil(price, 0) : maxPrice;
        });

        // Odstranime hlavnu kategoriu
        _.unset(subcategories, filter.category);

        // Aplikujeme filter na produkty
        const filteredProducts = _.reduce(categoryProducts, (result, product) => {
            if (toNumber(filter.subcategory) > 0 && !_.includes(product.categories, toNumber(filter.subcategory))) {
                // Je zadana subkategoria a nevyhovuje
                return result;
            }

            if (!_.isEmpty(filter.variables)) {
                // Su zadane variables
                let valid = true;

                _.each(filter.variables, v => {
                    if (!_.includes(product.variables, toNumber(v))) {
                        valid = false;
                    }
                });

                if (!valid) {
                    // Nevyhovuju variables
                    return result;
                }
            }

            if (!_.isEmpty(filter.marks)) {
                // Su zadane marks
                let valid = true;

                _.each(filter.marks, m => {
                    if (!_.includes(product.marks, toNumber(m))) {
                        valid = false;
                    }
                });

                if (!valid) {
                    // Nevyhovuju marks
                    return result;
                }
            }

            const filterVariants = !_.isEmpty(filter.variants);

            if (filterVariants) {
                // Su zadane varianty, staci ak jedna vyhovuje
                // Vytiahneme zoznam idciek variant
                const variants = _.reduce(product.variants, (result, { id }) => ([ ...result, id ]), []);
                let valid = false;

                _.each(filter.variants, v => {
                    if (_.includes(variants, toNumber(v))) {
                        valid = true;
                    }
                });

                if (!valid) {
                    // Nevyhovuju varianty
                    return result;
                }
            }

            if (filter.storage) {
                // Iba skladom
                let valid = false;

                _.each(product.variants, ({ id, stock }) => {
                    if (
                        stock > 0
                        && (
                            !filterVariants
                            || _.includes(filter.variants, toNumber(id))
                        )
                    ) {
                        // Je skladom a bud nefiltrujeme podla variant alebo filtrujeme a musi byt varianta skladom
                        valid = true;
                    }
                });

                if (!valid) {
                    // Nevyhovuje skladom
                    return result;
                }
            }

            const priceFrom = floor(minPrice + ((maxPrice - minPrice) * (filter.price[0] / 100)));
            let priceTo = ceil(minPrice + ((maxPrice - minPrice) * (filter.price[1] / 100)));

            if (product.price < priceFrom || product.price > priceTo) {
                // Nevyhovuje
                return result;
            }

            return [ ...result, product ];
        }, []);

        return {
            products: this.sortProducts(filteredProducts, sort),
            subcategories,
            subvariables,
            marks: allmarks,
            variants: allvariants,
            minPrice,
            maxPrice,
        };
    }

    /**
     * Zoradenie produktov.
     *
     * @param {Array} products
     * @param {string} sort
     *
     * @return {Array}
     */
    sortProducts(products, sort) {
        // Zoradime
        switch (sort) {
            case 'popular':
            default:
                // Oblubene
                products = _.orderBy(products, [product => product.ordered], ['desc']);
                break;

            case 'rating':
                // Hodnotenie
                products = _.orderBy(products, [product => product.rating], ['desc']);
                break;

            case 'newest':
                // Najnovsie
                products = _.orderBy(products, [product => product.id], ['desc']);
                break;

            case 'cheap':
                // Najlacnejsie
                products = _.orderBy(products, [product => product.price], ['asc']);
                break;

            case 'expensive':
                // Najdrahsie
                products = _.orderBy(products, [product => product.price], ['desc']);
                break;
        }

        return products;
    }

    /**
     * Dalsia stranka.
     */
    nextPage() {
        const { page } = this.state;

        this.setState({ page: page + 1 });
    }

    /**
     * Dalsia stranka oblubenych produktov.
     */
    nextPopularProductsPage() {
        const { popularProductsPage } = this.state;

        this.setState({ popularProductsPage: popularProductsPage === 1 ? 2 : 1 });
    }

    /**
     * Zoradime.
     *
     * @param {string} sort
     */
    sort(sort) {
        const { products } = this.state;

        this.setState({ page: 1, sort, products: this.sortProducts(products, sort) });
    }

    /**
     * Zobrazime/schovame kategoriu.
     *
     * @param {string} name
     */
    showSection(name) {
        const { hiddenSections } = this.state;

        if (_.has(hiddenSections, name)) {
            // Kategoria je schovana
            this.setState({ hiddenSections: _.omit(hiddenSections, [name]) })
            return;
        }

        this.setState({ hiddenSections: { ...hiddenSections, [name]: name } });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, navigate } = this.props;
        const {
            categories,
            subcategories,
            filter,
            products,
            page,
            perPage,
            sort,
            variants,
            variables,
            subvariables,
            marks,
            hiddenSections,
            minPrice,
            maxPrice,
            popularProductsPage,
            popularProductsPerPage,
            showMoreCategoryText,
        } = this.state;

        if (_.isEmpty(categories)) {
            // Nie su nacitane data
            return this.renderLoading();
        }

        let categoryName = toNumber(filter.category) === 0 ? __('Všetky produkty') : getText(eshop.categories[filter.category].name);
        let categoryText = toNumber(filter.category) === 0 ? '' : getText(eshop.categories[filter.category].text);

        if (filter.subcategory > 0) {
            // Filtrujeme podla subkategorie
            categoryName = getText(eshop.categories[filter.subcategory].name);
        }

        const showFilterStorage = !_.has(hiddenSections, 'storage');
        const showFilterCategories = !_.has(hiddenSections, 'categories');
        const showFilterVariants = !_.has(hiddenSections, 'variants');
        const showFilterPrice = !_.has(hiddenSections, 'price');

        // Vytiahneme 8 najoblubenejsich produktov
        const popularProducts = _.slice(_.orderBy(_.values(products), [product => product.ordered], ['desc']), 0, 8);

        // Rozdelime produkty na strankovanie
        const popularProductsChunks = _.chunk(popularProducts, popularProductsPerPage);

        // Filtrovanie podla ceny
        const filterFromPrice = round(minPrice + ((maxPrice - minPrice) * (filter.price[0] / 100)));
        const filterToPrice = round(minPrice + ((maxPrice - minPrice) * (filter.price[1] / 100)));

        // Filtrujeme podla?
        const filterByVariants = !_.isEmpty(filter.variants);
        const filterByVariables = !_.isEmpty(filter.variables);
        const filterByPrice = filter.price.length === 2 && (filter.price[0] > 0 || filter.price[1] < 100);

        // Sortovanie
        const sortContent = <div className="products__content__sort">
            {_.map({
                popular: __('Najpredávanejšie'),
                expensive: __('Od najdrahšieho'),
                cheap: __('Od najlacnejšieho'),
                newest: __('Najnovšie'),
                rating: __('Podľa hodnotenia'),
            }, (name, item) => (
                <ButtonBase
                    onClick={() => this.sort(item)}
                    className={sort === item ? 'active' : ''}
                    key={item}
                >{name}</ButtonBase>
            ))}
        </div>;

        const sortContentMobile = <div className="products__content__sort">
            <ButtonBase
                onClick={() => this.showDrawer('mobile-filter')}
            >
                <FilterIcon />
                <span>{__('Filter')}</span>
            </ButtonBase>
            <ButtonBase
                onClick={() => this.resetFilter()}
                className="active"
            >
                <DeleteIcon />
                <span>{__('Zmazať filter')}</span>
            </ButtonBase>
        </div>;

        // Filter
        const filterContent = <Filter
            eshop={eshop}
            navigate={navigate}
            filter={filter}
            onChangeFilter={(name, value) => this.onChangeFilter(name, value)}
            showSection={name => this.showSection(name)}
            showFilterStorage={showFilterStorage}
            showFilterCategories={showFilterCategories}
            showFilterVariants={showFilterVariants}
            categories={categories}
            subcategories={subcategories}
            onChangeCategory={id => this.onChangeCategory(id)}
            variants={variants}
            variables={variables}
            subvariables={subvariables}
            marks={marks}
            hiddenSections={hiddenSections}
            showFilterPrice={showFilterPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            filterFromPrice={filterFromPrice}
            filterToPrice={filterToPrice}
            sortContent={sortContent}
            resetFilter={() => this.resetFilter()}
            closeFilter={() => this.closeDrawer('mobile-filter')}
        />;

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '/obchod': __('Obchod'),
                }} />
                <div className="products">
                    {filterContent}
                    <div className="products__content">
                        <h1 className="products__content__title">{categoryName}</h1>
                        {categoryText !== ''
                            ? (<div className="products__content__text">
                                {showMoreCategoryText ? categoryText : _.truncate(categoryText, { length: 128 })}
                                {!showMoreCategoryText ? <ButtonBase
                                    onClick={() => this.setState({ showMoreCategoryText: true })}
                                >{__('Pokračovať')}</ButtonBase> : null}
                        </div>)
                            : null}
                        {filter.subcategory === 0 ? <div className="products__content__categories">
                            {_.map(subcategories, ({ image, name, count }, subcategoryId) => {
                                subcategoryId = toNumber(subcategoryId);
                                name = getText(name);

                                return (
                                    <ButtonBase
                                        onClick={() => this.onChangeFilter('subcategory', subcategoryId)}
                                        className="products__content__categories__category"
                                        key={subcategoryId}
                                    >
                                        <div className="products__content__categories__category__info">
                                            <div className="products__content__categories__category__info__name">{name}</div>
                                            <div className="products__content__categories__category__info__count">
                                                {`${count} ${plural(count, [__('produkt'), __('produkty'), __('produktov')])}`}
                                            </div>
                                        </div>
                                        <img src={getImageUrl(image)} alt={name} />
                                    </ButtonBase>
                                );
                            })}
                        </div> : null}
                        {!_.isEmpty(popularProducts) ? <div className="products__content__popular">
                            <h2 className="products__content__popular__title">{__('Najpredávanejšie')}</h2>
                            <div className="products__content__popular__items">
                                {_.map(popularProductsChunks, (chunk, chunkKey) => {
                                    if ((chunkKey + 1) > popularProductsPage) {
                                        // Dalsia stranka
                                        return null;
                                    }

                                    return _.map(chunk, ({ id, image, url, name, price, variables }, key) => {
                                        name = getText(name);

                                        return (
                                            <ButtonBase
                                                onClick={() => this.redirect(`/obchod/${getText(url)}`)}
                                                className="products__content__popular__items__item"
                                                key={id}
                                            >
                                                <div className="products__content__popular__items__item__left">
                                                    {image !== '' ? <img src={getImageUrl(image)} alt={name} /> : null}
                                                    <div className="products__content__popular__items__item__left__info">
                                                        <div className="products__content__popular__items__item__left__info__variables">
                                                            {_.map(_.slice(variables, 0, 3), variable_id => {
                                                                if (!_.has(eshop.variables, variable_id)) {
                                                                    return '';
                                                                }

                                                                return getText(eshop.variables[variable_id].name);
                                                            }).join(' / ')}
                                                        </div>
                                                        <div className="products__content__popular__items__item__left__info__name">{name}</div>
                                                    </div>
                                                </div>
                                                <div className="products__content__popular__items__item__amount">{formatAmount(getPrice(price))}</div>
                                            </ButtonBase>
                                        );
                                    });
                                })}
                            </div>
                        </div> : null}
                        {popularProducts.length > 4 ? <ButtonBase
                            onClick={() => this.nextPopularProductsPage()}
                            className={`products__content__popular-button ${popularProductsPage === popularProductsChunks.length ? 'show' : ''}`}
                        >
                            <span>{__('dalšie najpredávanejšie')}</span>
                            <ArrowIcon />
                        </ButtonBase> : null}
                        {!this.isMobile() ? sortContent : sortContentMobile}
                        <div className="products__content__filtered">
                            {(filterByVariants || filterByVariables || filterByPrice || filter.storage) && !this.isMobile() ? <Chip
                                onDelete={() => this.resetFilter()}
                                label={__('Zmazať filter')}
                                deleteIcon={<DeleteIcon />}
                            /> : null}
                            {filter.storage ? <Chip
                                onDelete={() => this.onChangeFilter('storage', false)}
                                label={__('Skladom')}
                                deleteIcon={<CloseIcon />}
                            /> : null}
                            {filterByVariants ? <Chip
                                onDelete={() => this.onChangeFilter('variants', {})}
                                label={`${__('Veľkosť')}: ${_.reduce(filter.variants, (result, variant) => ([ ...result, getText(eshop.variants[variant].name) ]), []).join(',')}`}
                                deleteIcon={<CloseIcon />}
                            /> : null}
                            {_.map(filter.variables, (subId, parentId) => <Chip
                                key={subId}
                                label={`${getText(eshop.variables[parentId].name)}: ${getText(eshop.variables[subId].name)}`}
                                onDelete={() => this.onChangeFilter('variables', { [parentId]: subId })}
                                deleteIcon={<CloseIcon />}
                            />)}
                            {filterByPrice
                                ? <Chip
                                    label={`${__('Cena')}: ${__('od')} ${formatAmount(getPrice(filterFromPrice), 0)} do ${formatAmount(getPrice(filterToPrice), 0)}`}
                                    onDelete={() => this.onChangeFilter('price', [0,100])}
                                    deleteIcon={<CloseIcon />}
                                /> : null}
                        </div>
                        {!_.isEmpty(products) ? <ProductsList
                            navigate={navigate}
                            eshop={eshop}
                            items={products}
                            page={page}
                            perPage={perPage}
                            onNextPage={() => this.nextPage()}
                        /> : null}
                        {_.isEmpty(products) ? <div className="products__content__empty">
                            <div className="products__content__empty__text">{__('Vášmu vyhľadávaniu nezodpovedá žiadny produkt')}</div>
                            {!this.isMobile() ? <ButtonBase
                                onClick={() => this.resetFilter()}
                            >{__('Zmazať filter')}</ButtonBase> : null}
                            {!this.isMobile() ? <div className="products__content__empty__button-text">{__('a začať znova')}...</div> : null}
                        </div> : null}
                    </div>
                </div>
                <ButtonBase
                    onClick={() => this.showDrawer('mobile-filter')}
                    className="filter-button"
                >
                    <FilterIcon strokeWidth={1} />
                    <div>{__('filter')}</div>
                </ButtonBase>
                {this.renderDrawer('mobile-filter', {
                    title: __('Filter'),
                    content: props => filterContent,
                })}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ProductsScreen));
