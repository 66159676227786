import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/buy.css';

/**
 * Ako nakupovat.
 */
class BuyScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Ako nakupovať');

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('Ako nakupovať'),
                }} />
                <div className="buy">
                    <h1 className="buy__title">{__('Ako nakupovať')}</h1>
                    <div className="buy__text">
                        <p><b>{__('Ako nakupovať?')}</b></p>
                        <p>{__('Tento obchod je moderným objednávkovým systémom, ktorý svojim zákazníkom umožňuje komfortný, pohodlný a efektívny nákup priamo od obrazovky počítača a radi by sme vám vysvetlili ako sa u nás objednáva tovar.')}</p>
                        <p>{__('Nie je to nič zložité a v mnohom sa celá operácia podobá nakupovaniu v normálnom bežnom obchode.')}</p>
                        <p><b>{__('Je registrácia povinná?')}</b></p>
                        <p>{__('Áno, registrácia je povinná. Anonymne sa môžete ľubovolne pohybovať po celom online nákupnom systéme a prezerať ponúkané produkty. Ale nákup je povolený iba registrovaným zákazníkom . Registrácia v našom systéme je samozrejme bezplatná. Všetci zákazníci potrebujú tiež prejsť objednávacím procesom v ktorom zadajú potrebné údaje, ktoré sú dôležité pre zrealizovanie objednávky.')}</p>
                        <p><b>{__('Ako sa zaregistrovať?')}</b></p>
                        <p>{__('Kliknite na odkaz "Môj účet" v hornom menu. V boxe "Nový zákazník" stlačte tlačítko "POKRAČUJ".Po vyplnení všetkých povinných údajov stlačte tlačitko "POKRAČUJ". Pokiaľ ste vyplnili všetko správne, budete mať v našej databáze vytvorený používateľský účet s vašim menom a prihlasovacím heslom.  Kedykoľvek budete neskôr v našom obchode chcieť nakupovať, stačí, keď sa prihlásite. Potom už nemusíte vypĺňať vaše osobné údaje vždy, keď budete chcieť odoslať vašu objednávku. Pokiaľ budete chcieť svoj používateľský účet vymazať, oznámte nám to a my sa o to postaráme.')}</p>
                        <p>{__('Osobné údaje takto vložené do našej databázy je možné kedykoľvek zmeniť, prípadne úplne vymazať. Stačí zvoliť odkaz zmeniť údaje na stránke "Môj účet". Môžete sa tiež kedykoľvek odhlásiť (odkaz odhlásiť).')}</p>
                        <p>{__('Asi najdôležitejší je odkaz prehľad vašich objednávok. Tu si môžete prezrieť, ktoré objednávky ste v našom obchode urobili, prípadne si podrobne nechať vypísať tovar, ktorý ste takto objednávali. Čo ale viac. Máte tu tiež prehľad o tom, čo sa práve s vašou objednávkou deje (bola spracovaná, neuhradená atď.).')}</p>
                        <p>{__('Máte tu tiež možnosť vloženia inej adresy ako je Vaša v prípade, že budete potrebovať aby sme Vám doručili tovar na inú ako firemnú adresu. To všetko nájdete v časti "Adresár" po kliknutí na stránku "Môj účet".')}</p>
                        <p>{__('Pokiaľ už ste zaregistrovaný a chcete sa prihlásiť do nášho nákupného systému, kliknite na "Môj účet", napíšte Váš email, vyberte si druhú možnosť pod názvom "Som už zaregistrovaný zákazník" a do ďalšieho políčka zadajte heslo, ktoré ste si zvolili pri registrácii.')}</p>
                        <p>{__('Pokiaľ zabudnete svoje prihlasovacie heslo, náš elektronický obchod vám ho pošle e-mailom. Zvoľte odkaz "zabudli ste heslo" na prihlasovacej stránke. Tu zadajte svoju e-mail adresu, ktorú ste zadali pri vytváraní vášho účtu. Pokiaľ náš obchod túto adresu nájde vo svojej databáze, zašle vám na ňu všetky potrebné informácie aj s novým heslom do nášho nákupného systému.')}</p>
                        <p><b>{__('Výber podľa kategórií - všetko čo potrebujete')}</b></p>
                        <p>{__('Všimnite si, že v ľavej časti každej stránky nášho obchodu je vždy zoznam kategórií prípadne podkategórií. Tu hneď nájdete vždy to čo potrebujete. Prostredníctvom spomenutého zoznamu sa dostanete do ktorejkoľvek časti nášho katalógu a rýchlo a bez zbytočného klikania naviac. Nechceme vás zaťažovať zbytočnými vecami, preto sme sa snažili nakupovanie čo najviac uľahčiť a spríjemniť.')}</p>
                        <p>{__('Každá kategória okrem toho, že obsahuje nejaký tovar, môže mať aj svoju vlastnú podkategóriu. Aj tieto podkategórie obsahujú tiež rôzne druhy tovarov.')}</p>
                        <p>{__('Kedykoľvek kliknete na niektorý názov kategórie alebo podkategórie, okamžite vám obchod ponúkne prehľad tovaru, ktorý je do tejto kategórie zaradený. Pokiaľ je prehľad tovaru dlhší a nevojde sa na jednu stranu, bude automaticky rozdelený na viac strán. Stačí kliknúť na číslo požadovanej strany v hornej alebo dolnej časti prehľadu a náš systém vám hneď túto stranu ponúkne.')}</p>
                        <p><b>{__('Prehľad tovaru')}</b></p>
                        <p>{__('Pokiaľ kliknete na niektorý názov kategórie, prípadne na odkaz výrobcov alebo si necháte vyhľadať položku, ktorá vás zaujíma (viď ďalej), váš prehliadač vám ponúkne prehľad tovaru. Každá položka tohto prehľadu obsahuje nielen názov tovaru, ale aj jeho kód. Naviac hneď môžete tento tovar vložiť do vášho elektronického košíka (viď košík). Prípadne, ak je už v košíku vložený, je možné ho samozrejme vybrať. Ak kliknete na názov tovaru, prehliadač vám ponúkne stránku tovaru. Táto stránka obsahuje okrem názvu a kódu tiež veľmi podrobný popis výrobku vrátane jeho obrázku. Aj z tejto stránky máte možnosť vložiť tovar do košíka aj s možnosťou výberu počtu kusov.')}</p>
                        <p><b>{__('Vyhľadávanie')}</b></p>
                        <p>{__('Veríme, že budete veľmi často v našom obchode hľadať tovar, ktorý by ste radi kúpili. Prichystali sme pre vás možnosť prehľadávať náš katalóg a to veľmi jednoducho a pohodlne. Na ľavej strane nájdete hneď hore políčko pre vyhľadávanie. Stačí, keď do neho zadáte názov hľadaného tovaru, prípadne jeho časť. Potom, čo stlačíte klávesu Enter, bude prehľadaná naša databáza a prehliadač vám ponúkne prehľad tovaru, ktorý zodpovedá vami zadanej podmienke. Ďalšou možnosťou je využiť tzv. rozšírené hľadanie. Zvoľte odkaz rozšírené hľadanie. Ponúknutá stránka obsahuje niekoľko možností ako určiť podmienku pre vyhľadávanie. Môžete tieto podmienky ľubovoľne kombinovať, vždy však musí byť minimálne jedna zvolená. Je možné napríklad zadať, že hľadáte tovar, ktorý má vo svojom názve slovo "PERO" a či je od výrobcu "ESSELTE". Aby sme predošli prípadným problémom so slovenskými názvami, funguje vyhľadávanie aj s mäkčeňmi a dĺžňami.')}</p>
                        <p><b>{__('Košík a pokladňa')}</b></p>
                        <p>{__('Keď vstúpite do nášho elektronického obchodu, máte v ruke akýsi pomyslený elektronický košík. Do neho môžete samozrejme vkladať tovar. Stačí ak pri vybranej položke stlačíte tlačidlo pridať do košíka. V tej chvíli je tento tovar vložený do vášho elektronického košíka. Prezrieť si celý košík môžete tak, že kliknete buď na nadpis v hornom menu "Nákupný košík" alebo v pravej časti zvolíte "Nákupný košík".')}</p>
                        <p><b>{__('Vkladanie tovaru do košíka')}</b></p>
                        <p>{__('Všade tam, kde je názov tovaru, nájdete aj možnosť vložiť tento tovar do košíka. Stačí stlačiť tlačidlo vložiť do košíka. Tovar je hneď do košíka vložený a náš elektronický obchod vás o tom informuje pridaním vybratého tovaru do nákupného košíka v hornej časti na pravej strane každej stránky.')}</p>
                        <p>{__('Pokiaľ si prajete zmeniť počet kusov daného tovaru, kliknite na odkaz NÁKUPNÝ KOŠÍK, otvorí sa Vám nová stránka, kde do políčka "Počet" vložte číselné vyjadrenie a stlačte tlačítko "Obnoviť košík".')}</p>
                        <p>{__('Pokiaľ si prajete čokoľvek odstrániť z košíka, označte políčko "Odstrániť" pri tovare, ktorý chcete odstrániť z nákupného košíka a stlačte tlačítko "Obnoviť košík".')}</p>
                        <p><b>{__('Pokladňa')}</b></p>
                        <p>{__('Pokladňa je v našom obchode spojená s košíkom, takže máte opäť možnosť prejsť tam zo stránky "Nákupný košík", keď kliknete na tlačítko "Objednať". Druhá možnosť sa skrýva v hornom menu vpravo v časti "Objednávka". Musíte tu zvoliť spôsob platby za vami vybraný tovar a tiež adresu veľkoskladu, kam chcete poslať objednávku.')}</p>
                        <p>{__('Náš elektronický obchod vám ponúkne ešte jednu stránku, kde máte naposledy možnosť všetko skontrolovať. Teraz stlačte tlačidlo potvrdiť. Vaša objednávka je v tejto chvíli odoslaná obchodníkovi. Vám príde e-mail s informáciami a potvrdením vašej objednávky.')}</p>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(BuyScreen));
