import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Sections, Blog } from '../components';
import { __, getText, toNumber } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchBlog, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/blog-screen.css';

/**
 * Blog.
 */
class BlogScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Magazín');

    /**
     * Default state.
     *
     * @type {{
     *     items: Array,
     *     categories: Object,
     *     activeCategory: number,
     *     page: number,
     *     perPage: number,
     *     sort: string,
     * }}
     */
    state = {
        items: [],
        categories: {},
        activeCategory: 0,
        page: 1,
        perPage: 8,
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { fetchBlog } = this.props;

        if (_.isEmpty(this.props.blog)) {
            // Nacitame blog
            await fetchBlog(this);
        }

        const { blog } = this.props;

        let categories = _.reduce(
            blog.categories,
            (result, category) => ({ ...result, [category.id]: { ...category, count: 0 } }),
            {}
        );

        // Prejdeme polozky
        _.each(blog.items, item => {
            // Prejdeme kategorie
            _.each(item.categories, category => {
                categories = { ...categories, [category]: { ...categories[category], count: categories[category].count + 1 } };
            });
        });

        this.setState({ categories, items: this.filterItems(0) });
    }

    /**
     * Filtrujeme clanky,
     *
     * @param {number} category
     *
     * @return {Array}
     */
    filterItems(category) {
        const { blog } = this.props;

        if (category === 0) {
            // Chceme vsetko
            return blog.items;
        }

        return _.reduce(blog.items, (result, item) => {
            if (!_.includes(item.categories, toNumber(category))) {
                // Nevyhovuje kategorii
                return result;
            }

            return [ ...result, item ];
        }, []);
    }

    /**
     * Event po zmene kategorie.
     *
     * @param {number} id
     */
    onChangeCategory(id) {
        this.setState({ page: 1, activeCategory: id, items: this.filterItems(id) });
    }

    /**
     * Dalsia stranka.
     */
    nextPage() {
        const { page } = this.state;

        this.setState({ page: page + 1 });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, blog, navigate } = this.props;
        const { categories, items, page, perPage, activeCategory } = this.state;

        if (_.isEmpty(blog)) {
            return this.renderLoading();
        }

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('Magazín'),
                }} />
                <div className="blog-screen">
                    <div className="blog-screen__header">
                        <h1 className="blog-screen__header__title">{__('Magazín')}</h1>
                        <div className="blog-screen__header__text">{__('Novinky a zaujímavosti zo sveta drogérie.')}</div>
                        <div className="blog-screen__header__categories">
                            <ButtonBase
                                onClick={() => this.onChangeCategory(0)}
                                className={`blog-screen__header__categories__category ${activeCategory === 0 ? 'active' : ''}`}
                            >{__('Všetko')}</ButtonBase>
                            {_.map(categories, ({ id, name }) => {
                                id = toNumber(id);

                                const active = id === activeCategory;

                                return (
                                    <ButtonBase
                                        onClick={() => this.onChangeCategory(active ? 0 : id)}
                                        className={`blog-screen__header__categories__category ${active ? 'active' : ''}`}
                                        key={id}
                                    >{getText(name)}</ButtonBase>
                                );
                            })}
                        </div>
                    </div>
                    <Blog
                        navigate={navigate}
                        eshop={eshop}
                        categories={categories}
                        items={_.toArray(items)}
                        page={page}
                        perPage={perPage}
                        onNextPage={() => this.nextPage()}
                    />
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings, blog }) => ({ eshop, settings, blog });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchBlog, fetchFavorites, fetchCompares, fetchLists })(BlogScreen));
